import AssessmentIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../common/layouts/basicLayout';

const gridItems: CardsGridItem[] = [
  {
    title: 'Halliburton',
    icon: InsertDriveFileIcon,
    path: '/relatorios/halliburton',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-halliburton-visualizar'],
  },
  {
    title: 'Draft DANFE Excel',
    icon: InsertDriveFileIcon,
    path: '/relatorios/danfe',
    requiredRoles: ['relatorio-draft-danfe-excel-visualizar'],
  },
  {
    title: 'Draft DANFE FAXE Excel',
    icon: InsertDriveFileIcon,
    path: '/relatorios/danfeFaxe',
    requiredRoles: ['relatorio-draft-danfe-excel-visualizar'],
  },
  {
    title: 'Planilha LA',
    icon: InsertDriveFileIcon,
    path: '/relatorios/la',
    requiredRoles: ['relatorio-planilha-la-visualizar'],
  },
  {
    title: 'Associated',
    icon: InsertDriveFileIcon,
    path: '/relatorios/associated',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-associated-visualizar'],
  },
  {
    title: 'Planilha Transportadoras',
    icon: InsertDriveFileIcon,
    path: '/relatorios/transportadoras',
    requiredRoles: ['relatorio-planilha-transportadoras-visualizar'],
  },
  {
    title: 'Red Bull',
    icon: InsertDriveFileIcon,
    path: '/relatorios/redbull',
    requiredRoles: ['red-bull-visualizar'],
  },
  {
    title: 'Acompanhamento Gerencial',
    icon: InsertDriveFileIcon,
    path: '/relatorios/gerencial',
    requiredRoles: ['relatorio-gerencial-visualizar']
  },
  {
    title: 'Processos em Andamento',
    icon: InsertDriveFileIcon,
    path: '/relatorios/processos',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Dashboard',
    icon: InsertDriveFileIcon,
    path: '/relatorios/dashboard',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'NF Emitidas',
    icon: InsertDriveFileIcon,
    path: '/relatorios/nfemitidas',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Livro contas',
    icon: InsertDriveFileIcon,
    path: '/relatorios/livrocontas',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Movimentação Financeira',
    icon: InsertDriveFileIcon,
    path: '/relatorios/movimentacao',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Devolução de Saldo Por Cliente',
    icon: InsertDriveFileIcon,
    path: '/relatorios/devolucao-saldo',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Processos Faturados Não Liquidados',
    icon: InsertDriveFileIcon,
    path: '/relatorios/processos-faturados',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Processos em Andamento com Saldo',
    icon: InsertDriveFileIcon,
    path: '/relatorios/processos-saldo',
    requiredRoles: ['relatorio-processos-visualizar']
  }
];

const RelatoriosPage = () => {
  return (
    <BasicLayout title={'Relatórios'} icon={<AssessmentIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default RelatoriosPage;
