import BasicLayout from '../../common/layouts/basicLayout';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PreviewIcon from '@mui/icons-material/Preview';
import EjectIcon from '@mui/icons-material/Eject';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Web from '@mui/icons-material/Web';

const gridItems: CardsGridItem[] = [
  {
    title: 'Planilhas',
    icon: PivotTableChartIcon,
    path: '/integracao/planilhas',
    subItems: true,
    requiredRoles: ['planilhas-visualizar'],
  },
  {
    title: 'TOTVS / Easy',
    icon: RttIcon,
    path: '/integracao/totvs-easy',
    subItems: true,
    requiredRoles: ['totvs-visualizar'],
  },
  {
    title: 'Croda',
    icon: AssessmentIcon,
    path: '/integracao/croda',
    subItems: true,
    requiredRoles: ['croda-visualizar'],
  },
  {
    title: 'OCR',
    icon: PreviewIcon,
    path: '/integracao/ocr',
  },
  {
    title: 'Triangulus',
    icon: EjectIcon,
    path: '/integracao/triangulus',
    subItems: true,
    requiredRoles: ['triangulus-visualizar'],
  },
  {
    title: 'Crawlers',
    icon: SmartToyIcon,
    path: '/integracao/crawlers',
    subItems: true,
  },
  {
    title: 'Roche',
    icon: Web,
    path: '/integracao/roche',
    subItems: true,
    requiredRoles: ['roche-visualizar'],
  },
  {
    title: 'Thermo-Fisher',
    icon: Web,
    path: '/integracao/thermo-fisher',
    subItems: true,
    requiredRoles: ['thermo-fisher-visualizar'],
  },
];

const IntegracaoPage = () => {
  return (
    <BasicLayout title={'Integração'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default IntegracaoPage;
