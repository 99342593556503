import { CorrespondenciasListItem } from '../../totvs.types';
import { fillAndAlignString, formatDateToDDMMAA, formatDateToDDMMAAAA, saveTxtFile } from '../totvsEscritaPage.helpers';
import { DespesaNumerario, Numerario } from './totvsEscritaNumerariosPage.types';

export const generateNumerarioTXT = (numerarios: Numerario[]) => {
  numerarios.forEach((numerario) => {
    let numerarioTXT = '';
    const capaNumerarioTXT = generateCapaNumerarioTXT(numerario);
    numerarioTXT += capaNumerarioTXT;
    numerario.despesas.forEach((despesa) => {
      const despesaNumerarioTXT = generateDespesaNumerarioTXT(despesa, numerario?.capa?.numero_po);
      numerarioTXT += despesaNumerarioTXT;
    });
    saveTxtFile(numerarioTXT, `numerario-${numerario.capa.numero_identificacao}`);
  });
};

const generateCapaNumerarioTXT = (numerario: Numerario) => {
  let capaNumerarioTXT = '';
  capaNumerarioTXT += fillAndAlignString(numerario.capa.tipo_de_registro, 2, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.numero_po, 15, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.filler, 3, 'left');
  capaNumerarioTXT += fillAndAlignString(formatDateToDDMMAAAA(numerario.capa.data_de_chegada), 8, 'left');
  capaNumerarioTXT += fillAndAlignString(formatDateToDDMMAAAA(numerario.capa.data_recebimento_documentos), 8, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.codigo_despachante, 3, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.agente_transportador_id, 3, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.numero_master, 18, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.tipo_de_declaracao, 2, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.urf_de_despacho, 7, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.urf_de_entrada, 7, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.recinto_alfandegado, 7, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.modalidade_despacho, 1, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.tipo_conhecimento, 2, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.tipo_documento_chegada_carga, 2, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.utilizacao, 1, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.numero_identificacao, 15, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.peso_bruto, 15, 'left', true, 4, ' ');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.numero_fatura_servico, 6, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.data_entrega_mercadoria, 8, 'left');
  capaNumerarioTXT += fillAndAlignString(numerario.capa.observacao, 250, 'left');
  capaNumerarioTXT += '\n';
  return capaNumerarioTXT;
};

const generateDespesaNumerarioTXT = (despesa: DespesaNumerario, numeroPo: string) => {
  if (!numeroPo) {
    numeroPo = despesa.referencia_despachante;
  }

  let despesaNumerarioTXT = '';
  despesaNumerarioTXT += fillAndAlignString(despesa.tipo_de_registro, 2, 'left');
  despesaNumerarioTXT += fillAndAlignString(numeroPo, 18, 'left');
  despesaNumerarioTXT += fillAndAlignString(formatDateToDDMMAAAA(despesa.data_prevista_pagamento), 8, 'left');
  despesaNumerarioTXT += fillAndAlignString(despesa.codigo_despesa, 3, 'left');
  despesaNumerarioTXT += fillAndAlignString(despesa.valor_despesa, 17, 'left', true, 2);
  despesaNumerarioTXT += fillAndAlignString('1S', 2, 'left');
  despesaNumerarioTXT += '\n';
  return despesaNumerarioTXT;
};

export const verifyNumerariosCorrelations = (
  numerarios: Numerario[],
  correlation: CorrespondenciasListItem | undefined
) => {
  const errors: string[] = [];
  const despachantes = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Despachante');
  const agentesDeCarga = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Agente de Carga');
  const despesasReceitas = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Despesas/Receitas');

  numerarios.forEach((numerario) => {
    const despachante = despachantes?.find(
      (despachante) => despachante.quickcomexId == numerario.capa.codigo_despachante
    );
    if (despachante) {
      numerario.capa.codigo_despachante = despachante.totvsId;
    } else {
      const errorMessage = `Correspondência de Despachante ${numerario.capa.codigo_despachante} não encontrada.`;
      if (!errors.includes(errorMessage)) {
        errors.push(errorMessage);
      }
    }
    const agenteDeCarga = agentesDeCarga?.find(
      (agenteDeCarga) => agenteDeCarga.quickcomexId == numerario.capa.agente_transportador_id
    );
    if (agenteDeCarga) {
      numerario.capa.agente_transportador_id = agenteDeCarga.totvsId;
    } else {
      const errorMessage = `Correspondência de Agente de Carga ${numerario.capa.agente_transportador_id} não encontrada.`;
      if (!errors.includes(errorMessage)) {
        errors.push(errorMessage);
      }
    }
    numerario.despesas.forEach((despesa) => {
      const despesaReceita = despesasReceitas?.find(
        (despesaReceita) => despesaReceita.quickcomexId == despesa.codigo_despesa
      );
      if (despesaReceita) {
        despesa.codigo_despesa = despesaReceita.totvsId;
      } else {
        const errorMessage = `Correspondência de Despesa/Receita ${despesa.codigo_despesa} não encontrada.`;
        if (!errors.includes(errorMessage)) {
          errors.push(errorMessage);
        }
      }
    });
  });
  return errors;
};
