import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ExtensionIcon from '@mui/icons-material/Extension';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../../common/layouts/basicLayout';
import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ListIcon from '@mui/icons-material/List'; 

const cover: CardsGridItem = {
    title: 'Integração',
    icon: PivotTableChartIcon,
    path: '/integracao',
};

const gridItems: CardsGridItem[] = [
    {
        title: 'Faturas',
        icon: ReceiptIcon,
        path: '/integracao/planilhas/faturas',
        requiredRoles: ['planilhas-visualizar'],
    },
    {
        title: 'Empresas Estrangeiras',
        icon: LocationCityIcon,
        path: '/integracao/planilhas/empresas-estrangeiras',
        requiredRoles: ['exportador-fabricante'],
    },
    {
        title: 'Catalogo de Produtos',
        icon: ListIcon,
        path: '/integracao/planilhas/catalogo',
        requiredRoles: ['planilhas-visualizar'],
    }
];

const PlanilhasPage = () => {
    return (
        <BasicLayout title={'Integração'} icon={<ExtensionIcon color={'secondary'} />}>
            <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
        </BasicLayout>
    );
};

export default PlanilhasPage;
