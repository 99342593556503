import { AUTO_DOWNLOAD_CONTROL, AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../../features/config-control';
import { LOCKED_FOCUS, UNLOCKED_FOCUS } from '../../features/focus-lock';
import {
  CREATE_MODE,
  CONSULT_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  TRANSFER_MODE,
  REVERSAL_MODE,
  EXTERNAL_MODE,
  BATCH_APPROVAL_MODE,
  NONE_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_CONSULT_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_TRANSFER_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
  NONE_SUB_BACKGROUND_MODE,
  CALCULATE_MODE,
  RELEASE_MODE,
  REGISTER_MODE,
} from '../../features/mode';
import { NO_REFRESH, REFRESH } from '../../features/refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PERFORMING_ACTION_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../../features/status';
import { isStrictEquals } from '../general/general-utils';

// STATUS

const isIdleStatus = (status) => isStrictEquals(IDLE_STATUS, status);

const isLoadingStatus = (status) => isStrictEquals(LOADING_STATUS, status);

const isAlternativeLoadingStatus = (status) => isStrictEquals(ALTERNATIVE_LOADING_STATUS, status);

const isPreparingActionStatus = (status) => isStrictEquals(PREPARING_ACTION_STATUS, status);

const isPerformingActionStatus = (status) => isStrictEquals(PERFORMING_ACTION_STATUS, status);

const isSuccessStatus = (status) => isStrictEquals(SUCCESS_STATUS, status);

const isFailureStatus = (status) => isStrictEquals(FAILURE_STATUS, status);

// REFRESH

const isRefresh = (status) => isStrictEquals(REFRESH, status);

const isNoRefresh = (status) => isStrictEquals(NO_REFRESH, status);

// MODES

const isCreateMode = (status) => isStrictEquals(CREATE_MODE, status);

const isConsultMode = (status) => isStrictEquals(CONSULT_MODE, status);

const isUpdateMode = (status) => isStrictEquals(UPDATE_MODE, status);

const isLockedMode = (status) => isStrictEquals(LOCKED_MODE, status);

const isTransferMode = (status) => isStrictEquals(TRANSFER_MODE, status);

const isReversalMode = (status) => isStrictEquals(REVERSAL_MODE, status);

const isCalculateMode = (status) => isStrictEquals(CALCULATE_MODE, status);

const isReleaseMode = (status) => isStrictEquals(RELEASE_MODE, status);

const isRegisterMode = (status) => isStrictEquals(REGISTER_MODE, status);

const isExternalMode = (status) => isStrictEquals(EXTERNAL_MODE, status);

const isApprovalBatchMode = (status) => isStrictEquals(BATCH_APPROVAL_MODE, status);

const isNoneMode = (status) => isStrictEquals(NONE_MODE, status);

const isBackgroundCreateMode = (status) => isStrictEquals(BACKGROUND_CREATE_MODE, status);

const isBackgroundConsultMode = (status) => isStrictEquals(BACKGROUND_CONSULT_MODE, status);

const isBackgroundUpdateMode = (status) => isStrictEquals(BACKGROUND_UPDATE_MODE, status);

const isBackgroundDeleteMode = (status) => isStrictEquals(BACKGROUND_DELETE_MODE, status);

const isBackgroundTransferMode = (status) => isStrictEquals(BACKGROUND_TRANSFER_MODE, status);

const isBackgroundReversalMode = (status) => isStrictEquals(BACKGROUND_REVERSAL_MODE, status);

const isNoneBackgroundMode = (status) => isStrictEquals(NONE_BACKGROUND_MODE, status);

const isSubCreateMode = (status) => isStrictEquals(SUB_CREATE_MODE, status);

const isSubConsultMode = (status) => isStrictEquals(SUB_CONSULT_MODE, status);

const isSubUpdateMode = (status) => isStrictEquals(SUB_UPDATE_MODE, status);

const isNoneSubMode = (status) => isStrictEquals(NONE_SUB_MODE, status);

const isSubBackgroundCreateMode = (status) => isStrictEquals(SUB_BACKGROUND_CREATE_MODE, status);

const isSubBackgroundConsultMode = (status) => isStrictEquals(SUB_BACKGROUND_CONSULT_MODE, status);

const isSubBackgroundUpdateMode = (status) => isStrictEquals(SUB_BACKGROUND_UPDATE_MODE, status);

const isSubBackgroundDeleteMode = (status) => isStrictEquals(SUB_BACKGROUND_DELETE_MODE, status);

const isNoneSubBackgroundMode = (status) => isStrictEquals(NONE_SUB_BACKGROUND_MODE, status);

// CONTROL

const isIdleControl = (status) => isStrictEquals(IDLE_CONTROL, status);

const isAutoLoadControl = (status) => isStrictEquals(AUTO_LOAD_CONTROL, status);

const isAutoDownloadControl = (status) => isStrictEquals(AUTO_DOWNLOAD_CONTROL, status);

// FOCUS MODE

const isLockedFocusMode = (status) => isStrictEquals(LOCKED_FOCUS, status);

const isUnlockedFocusMode = (status) => isStrictEquals(UNLOCKED_FOCUS, status);

export {
  isIdleStatus,
  isLoadingStatus,
  isAlternativeLoadingStatus,
  isPreparingActionStatus,
  isPerformingActionStatus,
  isSuccessStatus,
  isFailureStatus,
  isRefresh,
  isNoRefresh,
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isLockedMode,
  isTransferMode,
  isCalculateMode,
  isReleaseMode,
  isRegisterMode,
  isReversalMode,
  isExternalMode,
  isApprovalBatchMode,
  isNoneMode,
  isBackgroundCreateMode,
  isBackgroundConsultMode,
  isBackgroundUpdateMode,
  isBackgroundDeleteMode,
  isNoneBackgroundMode,
  isSubCreateMode,
  isSubConsultMode,
  isSubUpdateMode,
  isNoneSubMode,
  isSubBackgroundCreateMode,
  isSubBackgroundConsultMode,
  isSubBackgroundUpdateMode,
  isSubBackgroundDeleteMode,
  isBackgroundTransferMode,
  isBackgroundReversalMode,
  isNoneSubBackgroundMode,
  isIdleControl,
  isAutoLoadControl,
  isAutoDownloadControl,
  isLockedFocusMode,
  isUnlockedFocusMode,
};
