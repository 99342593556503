import { Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from '@material-ui/core';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  GridOn,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Print as PrintIcon,
  Receipt as ReceiptIcon,
  RotateLeft as RotateLeftIcon,
  SyncAlt as SyncAltIcon,
  Warning,
} from '@material-ui/icons';
import _, { isArrayLikeObject, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole, userHasUpdateRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import FaturaContext from '../../../../contexts/import/fatura/FaturaContext';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import {
  selectFaturaCount,
  selectFaturas,
  selectMode,
  selectRelatedSelectionFaturaList,
} from '../../../../features/fatura/faturaSelectors';
import {
  changeToBackgroundReversalMode,
  changeToBackgroundTransferMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToReversalMode,
  changeToTransferMode,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedMercadoriaModel,
  resetRelatedSelectionFaturaList,
  resetSubMode,
  setFollowUp,
  setImportador,
  setNumero,
  setRelatedEstornoModel,
  setRelatedSelectionAvailableItemList,
  setRelatedSelectionFaturaList,
  setRelatedTransferenciaModel,
  success,
} from '../../../../features/fatura/faturaSlice';
import {
  activateByIdAsync,
  calculateByIdAsync,
  fetchFaturaByFilterAsync,
  inactivateByIdAsync,
} from '../../../../features/fatura/faturaThunks';
import { setErrorFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { followUpActions } from '../../../../features/follow-up/followUpSlice';
import { mercadoriaMovimentacaoAPI } from '../../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXIconButton from '../../../../shared-components/button/QCXIconButton';
import QCXDataGrid from '../../../../shared-components/data-grid/QCXDataGrid';
import QCXCompoundDialog from '../../../../shared-components/dialog/QCXCompoundDialog';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSelectServicoAutocomplete from '../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import QCXSelectTipoContaFaturaAutocomplete from '../../../../shared-components/select-tipo-conta/QCXSelectTipoContaFaturaAutocomplete';
import {
  TIPO_CONTA_FATURA_LI,
  TipoContaFaturaUtils,
} from '../../../../shared-components/select-tipo-conta/tipoContaUtils';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import { MediaType } from '../../../../utils/api/api-utils';
import { FaturaUtils } from '../../../../utils/general/fatura/faturaUtils';
import { unnormalizeNumeral } from '../../../../utils/general/general-utils';
import {
  OperationUtils,
  isCalculadaStatus,
  isNaoCalculadaStatus,
} from '../../../../utils/general/operations/operationUtils';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import {
  isAlternativeLoadingStatus,
  isNoneMode,
  isReversalMode,
  isTransferMode,
} from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';

export default function FaturaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const { status } = useContext(FaturaContext);

  const dispatch = useDispatch();

  const theme = useTheme();

  const mode = useSelector(selectMode);
  const faturas = useSelector(selectFaturas);
  const relatedSelectionFaturaList = useSelector(selectRelatedSelectionFaturaList);

  const [isModalPendenciasOpen, setIsModalPendenciasOpen] = useState(false);
  const [faturaAtual, setFaturaAtual] = useState({});

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isTransfer = useMemo(() => isTransferMode(mode), [mode]);

  const isReversal = useMemo(() => isReversalMode(mode), [mode]);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionFaturaList ||
      (_.isArrayLikeObject(relatedSelectionFaturaList) && _.isEmpty(relatedSelectionFaturaList)),
    [relatedSelectionFaturaList]
  );

  const fetchAll = useCallback(() => {
    dispatch(
      fetchFaturaByFilterAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedMercadoriaModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionFaturaList());
    }
  }, [isNone, isEmptySelectionList]);

  const [handleCalcularByIdClickMiddleware, operationOfCalcularById] = useOperationMiddleware(async (data) => {
    dispatch(calculateByIdAsync(data));
  }, []);

  const handleConfigureCalcularByIdClick = useCallback(
    (row, popupState) => {
      const { id, numero, calculada } = row;

      const handleCalcularByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const isSelectedFaturaNaoCalculada = isNaoCalculadaStatus(calculada);

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoCalculo'),
              message: isSelectedFaturaNaoCalculada
                ? `${t('com.muralis.qcx.acoes.operacaoFaturaCalculada', { id, numero })}`
                : `${t('com.muralis.qcx.acoes.operacaoFaturaCalculada', { id, numero })} ${t(
                    'com.muralis.qcx.acoes.operacaoFaturaRecalculada'
                  )}`,
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: isSelectedFaturaNaoCalculada
                  ? t('com.muralis.qcx.acoes.calculo')
                  : t('com.muralis.qcx.acoes.recalculo'),
              }),
            },
          });
          const dataOfOperationCalcular = {
            id,
            meta: {
              calculada,
            },
          };

          handleCalcularByIdClickMiddleware(configureOperation, dataOfOperationCalcular);
        }
      };

      return handleCalcularByIdClick;
    },
    [handleCalcularByIdClickMiddleware]
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdAsync(data?.id));

        return;
      }

      dispatch(activateByIdAsync(data?.id));
    },
    []
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, numero, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao'),
              message: t('com.muralis.qcx.acoes.operacaoFaturaExcluida', {
                id,
                numero,
                acao: active ? t('com.muralis.qcx.estado.excluida') : t('com.muralis.qcx.estado.recuperada'),
              }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: active ? t('com.muralis.qcx.acoes.exclusao') : t('com.muralis.qcx.acoes.recuperacao'),
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const handleClickOnButtonNew = useCallback(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());
    },
    [configureDependencies]
  );

  const isCurrentNotCalculated = useCallback((current) => !OperationUtils.isCalculadaStatus(current?.calculada), []);

  const hasDifferentImporters = useCallback((current, index, array) => {
    if (index === 0) {
      return false;
    }

    const pivot = array[0];

    return current?.importador?.id !== pivot?.importador?.id;
  }, []);

  const hasNoItemsAdded = useCallback(
    (current) => _.isArrayLikeObject(current?.mercadorias) && current?.mercadorias?.length === 0,
    []
  );

  const hasBalanceToTransfer = useCallback((conta) => conta?.saldoAtual > 0, []);

  const canBeTransactionReversed = useCallback(
    (movimentacao) => movimentacao?.estornada === 'NAO_ESTORNADA' && movimentacao?.tipo === 'SAIDA',
    []
  );

  const hasTransactionsToReverse = useCallback(
    (conta) =>
      conta?.movimentacoes && conta?.movimentacoes?.length > 0
        ? conta?.movimentacoes?.some(canBeTransactionReversed)
        : conta?.saldoAtual < conta?.saldoInicial,
    [canBeTransactionReversed]
  );

  const handleEnableTransferMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToTransferMode());
  }, []);

  const [handleContinueToTransfer, formDialogContinueToTransferStatus, handleFormDialogContinueToTransferStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundTransferMode());

      history.push('/importacao/faturas/transferencia');
    }, [history]);

  const handleContinueToTransferSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToTransferStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionFaturaList = faturas.filter((fatura) => relatedSelectionFaturaList.includes(fatura?.id));

      let loadedSelectedFaturaList = [];
      try {
        loadedSelectedFaturaList = await Promise.all(
          mappedSelectionFaturaList.map(async (fatura) => {
            const response = await faturaAPI.fetchById(fatura?.id);
            return response.data;
          })
        );
      } catch (err) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: 'Erro ao buscar fatura para transferência.',
          })
        );
        return;
      }

      if (tipoOrigem === TIPO_CONTA_FATURA_LI) {
        const promises = loadedSelectedFaturaList.map((fatura) =>
          handleProcessoNewSubmit({
            cliente: fatura?.importador,
            servico: values?.servico,
            processo: fatura?.numero,
            shouldRaiseError: true,
          })
        );

        try {
          await Promise.all(promises);
        } catch (e) {
          const errorMessage = `Não é possivel seguir com a transferência, pois não foi possível criar os processos de follow-up. Erro no followup: ${e.message}.`;

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );

          return;
        }
      }

      const reducerToContaItemList = (previous, selectedFatura) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = selectedFatura?.mercadorias?.map(adaptToContaItem) || [];

        return [...previous, ...items];
      };

      const contaItemList = loadedSelectedFaturaList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchTransferBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarSaldoMercadoria', {
          mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToTransferStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        const contasEncontradas = response?.data;

        const noBalanceToTransfer = contasEncontradas?.every((conta) => !hasBalanceToTransfer(conta));

        if (noBalanceToTransfer) {
          dispatch(failure());

          handleFormDialogContinueToTransferStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.mensagem.faturaSaldoInsuficiente'),
            })
          );

          return;
        }

        const mappedMinifiedSelectionFaturaList = loadedSelectedFaturaList?.map((selectedFatura) => ({
          id: selectedFatura?.id,
          numero: selectedFatura?.numero,
          importador: {
            id: selectedFatura?.importador?.id,
            pessoa: {
              nome: selectedFatura?.importador?.pessoa?.nome,
            },
          },
          followUp: selectedFatura?.followUp,
          mercadorias: selectedFatura?.mercadorias?.map((itemFromSelectedFatura) => itemFromSelectedFatura?.id),
        }));

        const adaptToItemFatura = (conta) => {
          const relatedFatura = mappedMinifiedSelectionFaturaList?.find((selectedFatura) =>
            selectedFatura?.mercadorias?.includes(conta?.proprietario?.id)
          );

          return {
            ...conta,
            id: conta?.proprietario?.id,
            tipo: {
              description: conta?.tipo?.description,
            },
            proprietario: conta?.proprietario,
            saldoInicial: conta?.saldoInicial,
            saldoAtual: conta?.saldoAtual,
            fatura: {
              id: relatedFatura?.id,
              numero: relatedFatura?.numero,
            },
          };
        };

        const availableContas = contasEncontradas?.filter(hasBalanceToTransfer);

        const mappedAvailableConta = availableContas?.map(adaptToItemFatura) || [];

        const mappedAvailableContaItemList = mappedAvailableConta.sort((contaA, contaB) => {
          const primaryFactorA = contaA?.fatura?.numero;
          const primaryFactorB = contaB?.fatura?.numero;

          const secondaryFactorA = contaA?.proprietario?.item;
          const secondaryFactorB = contaB?.proprietario?.item;

          return primaryFactorA === primaryFactorB
            ? secondaryFactorA - secondaryFactorB
            : primaryFactorA - primaryFactorB;
        });

        const { importador: commonImportador } = mappedMinifiedSelectionFaturaList[0];

        const payloadFollowUpsCheck = {
          importador: {
            id: commonImportador?.id,
          },
          tipo: {
            description: tipoOrigem,
          },
        };

        const availableFollowUpsResponse = await mercadoriaMovimentacaoAPI
          .fetchAvailableFollowUpsByImporterAndType(payloadFollowUpsCheck)
          .catch((reason) => {
            const errorMessage = t('com.muralis.qcx.erro.erroConsultaProcessosFollowUp', {
              resposta: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
            });

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: errorMessage,
              })
            );

            suspendExecution = true;
          });

        if (suspendExecution) {
          return;
        }

        if (availableFollowUpsResponse?.status === 200) {
          const availableFollowUps = availableFollowUpsResponse?.data;

          if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
            dispatch(failure());

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.mensagem.processoFollowUPIndisponivel', {
                  tipo: TipoContaFaturaUtils.getLabelFaturaByValue(tipoOrigem),
                  importador: commonImportador?.pessoa?.nome,
                }),
              })
            );

            return;
          }

          dispatch(followUpActions.resetControl());
          dispatch(
            followUpActions.setList({
              data: availableFollowUps,
            })
          );

          dispatch(
            setRelatedTransferenciaModel({
              conta: {
                tipo: {
                  description: tipoOrigem,
                },
              },
            })
          );

          dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

          handleFormDialogContinueToTransferStatus(DONE_STATUS);
        }
      }
    },
    [faturas, hasBalanceToTransfer, relatedSelectionFaturaList, handleFormDialogContinueToTransferStatus]
  );

  const handleCloseFormDialogContinueToTransfer = useCallback(
    () => handleFormDialogContinueToTransferStatus(CLOSED_STATUS),
    [handleFormDialogContinueToTransferStatus]
  );

  const isFormDialogContinueToTransferOpen = useMemo(
    () => isOpenStatus(formDialogContinueToTransferStatus),
    [formDialogContinueToTransferStatus]
  );

  const handleConfirmTransfer = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedFaturas = faturas.filter((fatura) => relatedSelectionFaturaList.includes(fatura?.id));

      const faturasNaoCalculadas = mappedFaturas.some(isCurrentNotCalculated);

      if (faturasNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.faturaCalculadaTransferencia'),
          })
        );

        return;
      }

      const importadoresDiferentes = mappedFaturas.some(hasDifferentImporters);

      if (importadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.faturaSelecionadaImportador'),
          })
        );

        return;
      }

      const faturasSemItensAdicionados = mappedFaturas.some(hasNoItemsAdded);

      if (faturasSemItensAdicionados) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.faturaSelecionadaItens'),
          })
        );

        return;
      }

      handleContinueToTransfer();
    },
    [
      faturas,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      hasNoItemsAdded,
      handleContinueToTransfer,
      relatedSelectionFaturaList,
    ]
  );

  const handleCancelTransfer = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleEnableReversalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToReversalMode());
  }, []);

  const [handleContinueToReversal, formDialogContinueToReversalStatus, handleFormDialogContinueToReversalStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundReversalMode());

      history.push('/importacao/faturas/estorno');
    }, [history]);

  const handleContinueToReversalSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToReversalStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionFaturaList = faturas.filter((fatura) => relatedSelectionFaturaList.includes(fatura?.id));

      let loadedSelectedFaturaList = [];
      try {
        loadedSelectedFaturaList = await Promise.all(
          mappedSelectionFaturaList.map(async (fatura) => {
            const response = await faturaAPI.fetchById(fatura?.id);
            return response.data;
          })
        );
      } catch (err) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: 'Erro ao buscar fatura para transferência.',
          })
        );
        return;
      }

      const reducerToContaItemList = (previous, selectedFatura) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = FaturaUtils.sortItensByNumero(selectedFatura?.mercadorias?.map(adaptToContaItem) || []);

        return [...previous, ...items];
      };

      const contaItemList = loadedSelectedFaturaList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.consultaSaldoFaturaSelecionadaEstorno', {
          resposta: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToReversalStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        let contasEncontradas = response?.data;
        if (!contasEncontradas) {
          contasEncontradas = [];
        }

        const noTransactionsToReverse = contasEncontradas?.every((conta) => !hasTransactionsToReverse(conta));

        if (noTransactionsToReverse) {
          dispatch(failure());

          handleFormDialogContinueToReversalStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.mensagem.faturaSelecionadaSemTransfEstorno', {
                tipo: TipoContaFaturaUtils.getLabelFaturaByValue(tipoOrigem),
              }),
            })
          );

          return;
        }

        const reduceToItemMovimentacaoList = (previous, current) => {
          const adaptToItemMovimentacao = (movimentacao) => ({
            ...current,
            id: movimentacao?.id,
            tipo: movimentacao?.tipo,
            proprietario: current?.proprietario,
            valor: movimentacao?.valor,
            estornada: movimentacao?.estornada,
            operacao: movimentacao?.operacao,
            fatura: {
              id: movimentacao?.fatura?.id,
              atributosAdicionais: {
                tipo: movimentacao?.fatura?.atributosAdicionais?.tipo,
                processo: movimentacao?.fatura?.atributosAdicionais?.processo,
              },
            },
          });

          const mappedItemMovimentacaoList = current?.movimentacoes
            ?.filter(canBeTransactionReversed)
            ?.map(adaptToItemMovimentacao);

          return [...previous, ...mappedItemMovimentacaoList];
        };

        const availableContas = contasEncontradas?.filter(hasTransactionsToReverse);

        const mappedAvailableContaItemList = availableContas
          ?.reduce(reduceToItemMovimentacaoList, [])
          .sort((contaA, contaB) => {
            const primaryFactorA = contaA?.fatura?.atributosAdicionais?.processo;
            const primaryFactorB = contaB?.fatura?.atributosAdicionais?.processo;

            const secondaryFactorA = contaA?.proprietario?.item;
            const secondaryFactorB = contaB?.proprietario?.item;

            return primaryFactorA === primaryFactorB
              ? secondaryFactorA - secondaryFactorB
              : primaryFactorA - primaryFactorB;
          });

        dispatch(
          setRelatedEstornoModel({
            conta: {
              tipo: {
                description: tipoOrigem,
              },
            },
          })
        );

        dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

        handleFormDialogContinueToReversalStatus(DONE_STATUS);
      }
    },
    [faturas, canBeTransactionReversed, relatedSelectionFaturaList, handleFormDialogContinueToReversalStatus]
  );

  const handleCloseFormDialogContinueToReversal = useCallback(
    () => handleFormDialogContinueToReversalStatus(CLOSED_STATUS),
    [handleFormDialogContinueToReversalStatus]
  );

  const isFormDialogContinueToReversalOpen = useMemo(
    () => isOpenStatus(formDialogContinueToReversalStatus),
    [formDialogContinueToReversalStatus]
  );

  const handleConfirmReversal = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedFaturas = faturas.filter((fatura) => relatedSelectionFaturaList.includes(fatura?.id));

      const faturasNaoCalculadas = mappedFaturas.some(isCurrentNotCalculated);

      if (faturasNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.faturaCalculadaEstorno'),
          })
        );

        return;
      }

      const isImportadoresDiferentes = mappedFaturas.some(hasDifferentImporters);

      if (isImportadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.faturaSelecionadaImportador'),
          })
        );

        return;
      }

      handleContinueToReversal();
    },
    [
      faturas,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      handleContinueToReversal,
      relatedSelectionFaturaList,
    ]
  );

  const handleCancelReversal = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isTransfer || isReversal) {
        dispatch(setRelatedSelectionFaturaList(selectionModel));
      }
    },
    [isTransfer, isReversal]
  );

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());
        history.push(t('com.muralis.qcx.url.importacaoFaturasDetalhes', { id }));
      }
    },
    [configureDependencies, history]
  );

  const modalPendencias = useMemo(
    () => (
      <QCXCompoundDialog
        key="form-dialog-pendencias-mercadoria"
        id="form-dialog-pendencias-mercadoria"
        maxWidth="md"
        fullWidth
        open={isModalPendenciasOpen}
        onClose={() => setIsModalPendenciasOpen(false)}
      >
        <QCXCompoundDialog.Title
          style={{
            padding: '16px 32px 16px 24px',
          }}
        >
          <Grid item container justify="space-between">
            <Grid item xs={10}>
              <Typography
                variant="subtitle2"
                style={{
                  color: theme.palette.primary.light,
                }}
              >
                <strong>{t('com.muralis.qcx.fatura.pendenciasFatura').toUpperCase()}</strong>
              </Typography>
            </Grid>
            <Grid item container justify="flex-end" xs={2}>
              <QCXIconButton
                style={{
                  margin: '-16px -32px 0 0',
                  padding: '10px',
                  borderRadius: '4px',
                }}
                onClick={() => setIsModalPendenciasOpen(false)}
                disableTooltip
                disableRipple
              >
                <CloseIcon />
              </QCXIconButton>
            </Grid>
          </Grid>
        </QCXCompoundDialog.Title>
        <QCXCompoundDialog.Content
          style={{
            paddingBottom: '24px',
          }}
        >
          <QCXDataGrid
            columns={[
              {
                field: 'item',
                headerName: t('com.muralis.qcx.item.labelSingular'),
                flex: 15,
              },
              {
                field: 'sequencial',
                headerName: t('com.muralis.qcx.sequencial'),
                flex: 25,
              },
              {
                field: 'pagina',
                headerName: t('com.muralis.qcx.pagina'),
                flex: 20,
              },
              {
                field: 'pendencias',
                headerName: t('com.muralis.qcx.pendencias'),
                flex: 50,
              },
            ]}
            rows={faturaAtual?.pendencias}
            pageSize={faturaAtual?.pendencias?.length}
          />
        </QCXCompoundDialog.Content>
      </QCXCompoundDialog>
    ),
    [isModalPendenciasOpen, faturaAtual, history]
  );

  const buscarPendenciasFatura = (fatura) => {
    const pendencias = [];
    const pendenciasFatura = FaturaUtils.getPendenciasFaturaOcr(fatura);

    if (pendenciasFatura !== null) {
      pendenciasFatura.fatura.forEach((pendencia) => {
        pendencias.push({
          id: uuid(),
          item: '-',
          sequencial: '-',
          pagina: '-',
          pendencias: pendencia || '-',
        });
      });

      pendenciasFatura.mercadorias
        .sort((m1, m2) => m1.item - m2.item)
        .forEach((pendencia) => {
          pendencias.push({
            id: uuid(),
            ...pendencia,
          });
        });
    }

    return pendencias;
  };

  const handleClickOnViewPendenciesButton = useCallback(
    (id) => {
      const fatura = faturas?.find((f) => f.id === id);

      const pendencias = buscarPendenciasFatura(fatura);

      if (pendencias.length > 0) {
        const faturaToSet = {
          id,
          pendencias,
        };
        setFaturaAtual(faturaToSet);
        setIsModalPendenciasOpen(true);
      }
    },
    [faturaAtual, faturas]
  );

  const faturaTemPendencias = useCallback(
    (id) => {
      const fatura = faturas?.find((f) => f.id === id);
      const origemFaturaOcr = fatura?.origem === 'OCR';
      let metadata = {};
      try {
        metadata = JSON.parse(fatura?.metadata);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      const { temPendencias } = metadata;

      if (!origemFaturaOcr) {
        return false;
      }

      return Boolean(temPendencias);
    },
    [faturas]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(followUpActions.changeToConsultMode());

        history.push(`/importacao/follow-up/${data.id}/lista`);
      }
    },
    [history]
  );

  const pageRole = useMemo(() => 'importacao-fatura', []);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        valueGetter: ({ row }) => row?.id,
      },
      {
        field: 'numero',
        headerName: t('com.muralis.qcx.fatura.numero'),
        type: 'string',
        flex: 250,
        valueGetter: ({ row }) => row?.numero || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        flex: 240,
        valueGetter: ({ row: { importador } }) => importador || '-',
      },
      {
        field: 'quantidadeItens',
        headerName: t('com.muralis.qcx.quantidadeItens'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 170,
        valueGetter: ({ row }) => row?.qtdItens,
      },
      {
        field: 'valorTotalMoeda',
        headerName: t('com.muralis.qcx.total'),
        headerAlign: 'right',
        align: 'right',
        type: 'number',
        flex: 180,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.total, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'calculada',
        headerName: t('com.muralis.qcx.calculada'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 160,
        valueGetter: ({ row }) => isCalculadaStatus(row?.calculada),
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        renderCell: ({ row: { id, numero, calculada, active, followUp } }) => (
          <>
            <QCXPopupState popupId="popup-menu-fatura">
              {(popupState) => (
                <>
                  <Tooltip title="Visualizar Lista" arrow>
                    <IconButton
                      key={`btn-lista-view-${id}`}
                      name={`btn-lista-view-${id}`}
                      onClick={(event) => {
                        handleConsultarListClick(event, followUp);
                      }}
                    >
                      <GridOn color="secondary" size={20} />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                    disabled={isTransfer || isReversal}
                  >
                    <MoreHorizIcon color={isTransfer || isReversal ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    {faturaTemPendencias(id) && (
                      <MenuItem
                        onClick={(event) => {
                          popupState.close(event);

                          handleClickOnViewPendenciesButton(id);
                        }}
                      >
                        <QCXListItemIcon>
                          <Warning fontSize="small" color="secondary" />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.pendencias').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleClickOnEditButton(event, id);
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                      }}
                      disabled
                    >
                      <QCXListItemIcon>
                        <PrintIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasUpdateRole(authInfo, pageRole) && (
                      <MenuItem
                        onClick={handleConfigureCalcularByIdClick(
                          {
                            id,
                            numero,
                            calculada,
                          },
                          popupState
                        )}
                      >
                        <QCXListItemIcon>
                          <FaCalculatorIcon color="green" size={20} />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {isNaoCalculadaStatus(calculada)
                            ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                            : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                    {userHasDeleteRole(authInfo, pageRole) && (
                      <MenuItem
                        key={`btn-excluir${id}`}
                        name={`btn-excluir-${id}`}
                        onClick={handleConfigureActivateOrInactivateByIdClick(
                          {
                            id,
                            numero,
                            active,
                          },
                          popupState
                        )}
                        disabled={!active}
                      >
                        <QCXListItemIcon>
                          <DeleteIcon fontSize="small" color={active ? 'error' : 'disabled'} />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      authInfo,
      pageRole,
      isTransfer,
      isReversal,
      handleClickOnEditButton,
      handleConfigureCalcularByIdClick,
      handleConfigureActivateOrInactivateByIdClick,
      faturaTemPendencias,
    ]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.fatura.labelPlural'),
      },
      default: true,
    },
  ];

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoFaturasNovo'));
    },
    [configureDependencies, history]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const parseToFormData = useCallback((data) => {
    const { novosArquivos, ...restData } = data;
    const novoArquivoMeta = {
      idioma: null,
      totalItens: null,
      number: null,
      date: null,
    };
    const novosArquivosMeta = [];

    const formData = new FormData();

    if (isArrayLikeObject(novosArquivos)) {
      novosArquivos.forEach((arquivo) => {
        if (arquivo?.fatura?.length > 0) {
          formData.append('files[]', arquivo?.fatura[0]);
          const meta = _.clone(novoArquivoMeta);
          meta.idioma = arquivo?.idioma || null;
          meta.totalItens = arquivo?.totalItens || null;
          meta.number = arquivo?.numeroFatura || null;
          meta.date = arquivo?.dataFatura || null;
          novosArquivosMeta.push(meta);
        }
      });
    }

    if (!isArrayLikeObject(restData?.arquivos)) {
      restData.arquivos = [];
    }

    const json = JSON.stringify(restData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: novosArquivosMeta };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogNewStatus(SUBMITTING_STATUS);

      const clienteId = values?.cliente?.id;
      const servicoId = values?.servico?.id;

      const newFollowUp = {
        numero: values?.processo,
        importador: {
          id: clienteId,
        },
        servico: {
          id: servicoId,
        },
      };

      try {
        const formData = parseToFormData(newFollowUp);
        const response = await followUpAPI.register(formData, [], {
          // não vai estourar uma exception para status codes 2xx ou 303 (see other)
          validateStatus: (requestStatus) => requestStatus < 300 || requestStatus === 303,
        });
        if (response?.status === 201 || response?.status === 303) {
          const created = response?.data;
          dispatch(
            setNumero({
              numero: created.numero,
            })
          );
          dispatch(
            setImportador({
              importador: {
                id: clienteId,
              },
            })
          );
          dispatch(
            setFollowUp({
              followUp: {
                id: created.id,
              },
            })
          );

          if (!values?.shouldRaiseError) {
            handleFormDialogNewStatus(DONE_STATUS);
          }

          dispatch(success());
        }
      } catch (error) {
        if (values?.shouldRaiseError) {
          throw new Error(error?.response?.data?.message);
        }

        handleFormDialogNewStatus(FAIL_STATUS);
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLI');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLIEspecifico', {
            erro: error?.response?.data?.message,
          });
        }
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogNewStatus]
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchFaturaByFilterAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectFaturaCount);

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.fatura.labelPlural'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        ...(isNone
          ? {
              buttonNew: {
                description: t('com.muralis.qcx.fatura.novoRegistroFatura'),
                onClick: handleButtonNewClick,
              },
            }
          : {}),
        others: [
          ...(isNone
            ? [
                {
                  description: <SyncAltIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.transferir'),
                  },
                  onClick: handleEnableTransferMode,
                },
                {
                  description: <RotateLeftIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.estornar'),
                  },
                  onClick: handleEnableReversalMode,
                },
              ]
            : []),
          ...(isTransfer || isReversal
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecaoDestino', {
                      destino: isTransfer
                        ? t('com.muralis.qcx.transferencia.envioTransferencia')
                        : t('com.muralis.qcx.estorno.envioEstorno'),
                    }),
                  },
                  onClick: isTransfer ? handleConfirmTransfer : handleConfirmReversal,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: isTransfer ? handleCancelTransfer : handleCancelReversal,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        checkboxSelection: isTransfer || isReversal,
        selectionModel: relatedSelectionFaturaList,
        onSelectionModelChange: handleSelectionModelChange,
        rowsCount,
        onConfigChangeCallback: handleConfigChange,
      },
    }),
    [
      columns,
      breadcrumbs,
      isTransfer,
      isReversal,
      isEmptySelectionList,
      relatedSelectionFaturaList,
      handleSelectionModelChange,
      handleClickOnButtonNew,
      handleEnableTransferMode,
      handleConfirmTransfer,
      handleCancelTransfer,
      handleEnableReversalMode,
      handleConfirmReversal,
      handleCancelReversal,
    ]
  );

  return (
    <>
      {modalPendencias}
      <SimpleConsultPageTemplate
        pageProps={templateProps.page}
        controlProps={templateProps.control}
        tableProps={templateProps.table}
        tableData={faturas}
        isLoading={isAlternativeLoading}
        authInfo={authInfo}
        requiredRoles={[pageRole]}
      >
        <QCXFinalFormDialog
          key="form-dialog-processo-registro-manual-li"
          id="form-dialog-processo-registro-manual-li"
          title={t('com.muralis.qcx.fatura.novoRegistroFatura')}
          content={t('com.muralis.qcx.preencherCampoContinuar')}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.continuar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={isFormDialogNewOpen}
          initialValues={{}}
          onSubmit={handleProcessoNewSubmit}
          onClose={handleCloseFormDialogNew}
        >
          {() => (
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <QCXFinalTextField
                    id="text-field-processo"
                    key="text-field-processo"
                    name="processo"
                    label={t('com.muralis.qcx.processo')}
                    validate={required}
                  />
                </QCXMoveFocusInside>
              </Grid>
              <Grid item xs={12}>
                <QCXSelectClienteAutocomplete
                  id="autocomplete-select-cliente-field"
                  key="autocomplete-select-cliente-field"
                  name="cliente.id"
                  label={t('com.muralis.qcx.cliente.label')}
                  initialValues={undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <QCXSelectServicoAutocomplete
                  id="autocomplete-select-servico-field"
                  key="autocomplete-select-servico-field"
                  name="servico.id"
                  label={t('com.muralis.qcx.servico.labelSingular')}
                  initialValues={undefined}
                  fieldProps={{ validate: required }}
                  produto="FATURA"
                  required
                />
              </Grid>
            </Grid>
          )}
        </QCXFinalFormDialog>
        <QCXFinalFormDialog
          key="form-dialog-fatura-tranferencia"
          id="form-dialog-fatura-tranferencia"
          title={t('com.muralis.qcx.transferencia.transferenciaDestino')}
          content={t('com.muralis.qcx.preencherCampoContinuar')}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.continuar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={isFormDialogContinueToTransferOpen}
          initialValues={{}}
          onSubmit={handleContinueToTransferSubmit}
          onClose={handleCloseFormDialogContinueToTransfer}
        >
          {({ values }) => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <QCXSelectTipoContaFaturaAutocomplete
                    id="select-field-tipo-conta-fatura-transferencia"
                    key="select-field-tipo-conta-fatura-transferencia"
                    name="tipo.description"
                    label={t('com.muralis.qcx.transferencia.destino')}
                    fieldProps={{
                      validate: required,
                    }}
                    required
                  />
                </QCXMoveFocusInside>
              </Grid>

              {values?.tipo?.description === TIPO_CONTA_FATURA_LI && (
                <Grid item xs={12}>
                  <QCXSelectServicoAutocomplete
                    id="autocomplete-select-servico-field"
                    key="autocomplete-select-servico-field"
                    name="servico.id"
                    label={t('com.muralis.qcx.servico.labelSingular')}
                    initialValues={undefined}
                    fieldProps={{ validate: required }}
                    produto="LI"
                    required
                  />
                </Grid>
              )}
            </Grid>
          )}
        </QCXFinalFormDialog>
        <QCXFinalFormDialog
          key="form-dialog-fatura-estorno"
          id="form-dialog-fatura-estorno"
          title={t('com.muralis.qcx.estorno.origemItens')}
          content={t('com.muralis.qcx.preencherCampoContinuar')}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.continuar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={isFormDialogContinueToReversalOpen}
          initialValues={{}}
          onSubmit={handleContinueToReversalSubmit}
          onClose={handleCloseFormDialogContinueToReversal}
        >
          {() => (
            <QCXMoveFocusInside>
              <QCXSelectTipoContaFaturaAutocomplete
                id="select-field-tipo-conta-fatura-estorno"
                key="select-field-tipo-conta-fatura-estorno"
                name="tipo.description"
                label={t('com.muralis.qcx.origem')}
                fieldProps={{
                  validate: required,
                }}
                required
              />
            </QCXMoveFocusInside>
          )}
        </QCXFinalFormDialog>
        <QCXConfirmDialog
          key="confirm-dialog-calculo-fatura"
          id="confirm-dialog-calculo-fatura"
          open={operationOfCalcularById?.active}
          title={operationOfCalcularById?.title}
          content={operationOfCalcularById?.message}
          endContent={operationOfCalcularById?.endMessage}
          onConfirm={operationOfCalcularById.confirm}
          onClose={operationOfCalcularById?.reset}
        />
        <QCXConfirmDialog
          key="confirm-dialog-activate-inactivate-fatura"
          id="confirm-dialog-activate-inactivate-fatura"
          open={operationOfActivateOrInactivateById?.active}
          title={operationOfActivateOrInactivateById?.title}
          content={operationOfActivateOrInactivateById?.message}
          endContent={operationOfActivateOrInactivateById?.endMessage}
          onConfirm={operationOfActivateOrInactivateById.confirm}
          onClose={operationOfActivateOrInactivateById?.reset}
        />
      </SimpleConsultPageTemplate>
    </>
  );
}
