import { Box, Grid, makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import QCXFinalFormDataReplicator from '../../form-data-replicator/QCXFinalFormDataReplicator';
import QCXFinalAtributoNveBondManager from '../QCXFinalAtributoNveBondManager';

const useStyles = makeStyles((theme) => ({
  gridField: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '8px',
  },
  leftSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  rightSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));

export default function RepassarAtributosNVE({ isLoading, isConsult, isCreate, isUpdate, adicao }) {
  const form = useForm();
  const classes = useStyles();

  const hasSetInitialValuesRef = useRef(false);
  const [replicated, setReplicated] = useState(false);

  const handleResetFormData = useCallback((formParam) => {
    formParam.restart({});
  }, []);

  useEffect(() => {
    if (isLoading || hasSetInitialValuesRef.current) return;

    const { values } = form.getState();

    if (values?.atributos) {
      setTimeout(() => {
        hasSetInitialValuesRef.current = true;
        form.change('ignorableFields', {
          atributos: values.atributos.map((a) => ({ ...a, especificacao: {} })),
        });
      }, 250);
    }
  }, [form, isLoading]);

  const formatSelection = (item) => ({ ...item });

  const itens = adicao?.mercadorias ?? [];

  const tableProps = useMemo(
    () => ({
      title: 'Partnumbers',
      rowsCount: itens.length,
      columns: [
        {
          field: 'item',
          headerName: 'Item',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'status',
          headerName: 'Especificação',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.adicionais?.especificacao || '-',
        },
        {
          field: 'ncm',
          headerName: 'NCM',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }) => row?.ncm?.code || '-',
        },
      ],
      data: itens,
    }),
    [itens]
  );

  const atributosNveProps = useMemo(
    () => ({
      modes: { isConsult, isCreate, isUpdate },
      classes,
      listProps: {
        rootName: 'ignorableFields.atributos',
      },
    }),
    [classes, isConsult, isCreate, isUpdate]
  );

  return (
    <Grid container sm={12}>
      <QCXFinalFormDataReplicator
        isConsult={isConsult}
        isLoading={false}
        tableProps={tableProps}
        onReset={handleResetFormData}
        replicated={replicated}
        handleSetReplicated={setReplicated}
        clearSelection={false}
        formatSelection={formatSelection}
        hideControlButtons
      >
        <>
          <Box mt={4}>
            <QCXFinalAtributoNveBondManager {...atributosNveProps} />
          </Box>
        </>
      </QCXFinalFormDataReplicator>
    </Grid>
  );
}
