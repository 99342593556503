import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { selectDeclaracaoImportacao } from '../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { selectPaises } from '../../../features/pais/paisSlice';
import QCXInfoAlert from '../../../shared-components/alert/QCXInfoAlert';
import QCXAloneInlineBoxWrapper from '../../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalCheckboxField from '../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalBondManagerOld from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalNumericDecimalField from '../../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalNumericIntegerField from '../../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalOnblurMaskedField from '../../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXSelectMoedaAutocomplete from '../../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalSelectAplicacaoMercadoriaAutocomplete from '../../../shared-components/select-aplicacao-mercadoria/QCXFinalSelectAplicacaoMercadoriaAutocomplete';
import QCXSelectCondicaoVendaAutocomplete from '../../../shared-components/select-condicao-venda/QCXSelectCondicaoVendaAutocomplete';
import { formatLINumber } from '../../../utils/hooks/form/field/formatters';
import { numberOnly } from '../../../utils/hooks/form/field/parsers';
import QCXFormSubtitle from '../../form-title/QCXFormSubtitle';
import QCXNcmNaladiFormGroup from '../../ncm-naladi-form-group/QCXNcmNaladiFormGroup';
import QCXFinalAtributoNveBondManager from '../QCXFinalAtributoNveBondManager';
import QCXSelectCertificadoAutocomplete from './QCXSelectCertificadoAutocomplete';
import QCXSelectPaisAutocomplete from './QCXSelectPaisAutocomplete';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXSelectTipoDocumentoVinculadoAutocomplete from '../../../shared-components/select-tipo-documento-vinculado/QCXSelectTipoDocumentoVinculadoAutocomplete';
import { isStrictEquals } from '../../../utils/general/general-utils';
import {
  exportacaoEstrangeiraDeclaracaoImportacaoActions,
  exportacaoEstrangeiraDeclaracaoImportacaoSelectors,
} from '../../../features/exportacao-estrangeira-declaracao-importacao/exportacaoEstrangeiraDeclaracaoImportacaoSlice';
import { TIPO_DECLARACAO_DUIMP } from '../../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';

function MercadoriaAdicaoForm({
  isConsult,
  isSubConsult,
  isSubNone,
  classes,
  submitSucceeded,
  modes,
  isSomeTipoDeclaracaoBy,
}) {
  const { t } = useTranslation();

  const { values } = useFormState();
  const di = useSelector(selectDeclaracaoImportacao);

  const isRelatedLi = (currentValues) => !!currentValues?.li?.registro;
  const despesaNaCapa = useMemo(() => di?.despesa?.valorMoeda !== 0, [di]);

  const list = useSelector(selectPaises);
  function shouldShowDe() {
    const value = list.find((item) => item?.id === di?.paisProcedencia?.id);

    if (!value) {
      return false;
    }

    return value.code === '63' || value.code === '105' || value.code === '845' || value.code === '586';
  }

  const exportacaoEstrangeiraListProps = useMemo(
    () => ({
      name: 'declaracaoExportacoes',
      columns: [
        {
          field: 'numero',
          headerName: 'Número da DE',
          headerAlign: 'left',
          align: 'left',
          flex: 180,
          valueGetter: ({ row }) => row?.numero || '-',
        },
        {
          field: 'inicio',
          headerName: 'Início',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.inicio || '-',
        },
        {
          field: 'fim',
          headerName: 'Fim',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.fim || '-',
        },
        {
          field: 'pais',
          headerName: 'Pais',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.pais || '-',
        },
        {
          field: 'numCertificado',
          headerName: 'Nº Certificado',
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.numCertificado || '-',
        },
        {
          field: 'item',
          headerName: 'Item',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'qtdUnidadeEstatistica',
          headerName: 'Qtd. na Unidade Estatística',
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.qtdUnidadeEstatistica || '-',
        },
      ],
    }),
    []
  );

  const handleExportacaoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some((vinculo) => isStrictEquals(vinculo.numero, currentVinculo.numero)),
    []
  );

  const handleAddExportacaoExt = useCallback((currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      numero: currentVinculo.numero,
      inicio: currentVinculo.inicio,
      fim: currentVinculo.fim,
      certificado: currentVinculo.certificado,
      pais: currentVinculo.pais,
      numCertificado: currentVinculo.numCertificado,
      item: currentVinculo.item,
      qtdUnidadeEstatistica: currentVinculo.qtdUnidadeEstatistica,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateExportacaoExt = useCallback((currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, currentVinculo?.id) ? currentVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  });

  const atributosNveProps = useMemo(
    () => ({
      modes,
      classes,
      listProps: {
        rootName: 'atributos',
      },
    }),
    [modes, classes]
  );

  const exportacaoEstrangeiraReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectMode,
        selectModel: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: exportacaoEstrangeiraDeclaracaoImportacaoActions.loading,
        resetStatus: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetMode,
        setModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.setModel,
        resetModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const exportacaoEstrangeiraFormProps = useMemo(() => ({
    rootName: 'ignorableFields.de',
    fields: [
      {
        name: 'numero',
        label: 'Número da DE',
      },
      {
        name: 'inicio',
        label: 'Início',
      },
      {
        name: 'fim',
        label: 'Fim',
      },
      {
        name: 'certificado',
        label: 'Certificado Mercosul',
      },
      {
        name: 'pais',
        label: 'Pais',
      },
      {
        name: 'numCertificado',
        label: 'Nº Certificado',
      },
      {
        name: 'item',
        label: 'Item',
      },
      {
        name: 'qtdUnidadeEstatistica',
        label: 'Qtd. na Unidade Estatistica',
      },
    ],
  }));

  return (
    <>
      <Grid item container xs={12} sm={12} md={12} lg={12}>
        {isRelatedLi(values) && (
          <QCXAloneInlineBoxWrapper>
            {(fieldProps) => (
              <QCXFinalTextField
                id="text-field-registro-li"
                key="text-field-registro-li"
                name="li.registro"
                label={t('com.muralis.qcx.numeroRegistroLI')}
                disabled
                {...fieldProps}
              />
            )}
          </QCXAloneInlineBoxWrapper>
        )}
      </Grid>
      <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalOnblurMaskedField
            id="text-field-numeroLI"
            key="text-field-numeroLI"
            name="mercadorias[0].numeroLI"
            label={t('com.muralis.qcx.mercadoria.numeroLI')}
            format={formatLINumber}
            parse={numberOnly}
            maxLength={11}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalDatePickerField
            id="registro-date-field"
            key="registro-date-field"
            name="mercadorias[0].dataRegistro"
            label={t('com.muralis.qcx.mercadoria.dataRegistro')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      </Grid>
      {/* Seção de duas Colunas */}
      <Grid item container spacing={2} xs={12}>
        <Grid item container xs={12} sm={12} md={6} lg={6}>
          <QCXNcmNaladiFormGroup modes={modes} />
          <Grid item className={classes.rateioCheckbox} xs={12} sm={12} md={12} lg={12}>
            <QCXFinalCheckboxField
              id="checkbox-rateio-peso-liquido-field"
              key="checkbox-rateio-peso-liquido-field"
              name="rateiaPesoLiquido"
              label={t('com.muralis.qcx.rateiaPesoLiquidoItensAdicao')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <QCXFinalNumericDecimalField
              id="numeric-decimal-quantidade-estatistica-field"
              key="numeric-decimal-quantidade-estatistica-field"
              name="quantidadeEstatistica"
              label={t('com.muralis.qcx.quantidade.quantidadeEstatistica')}
              disabled={isConsult || isSubConsult || isSubNone}
              scale={7}
            />
          </Grid>
          {despesaNaCapa && (
            <Grid
              item
              style={{
                paddingBottom: '16px',
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <QCXInfoAlert>
                O campos de valores das despesas na adição são desabilitados caso haja despesa informada na capa da DI e
                vice-versa
              </QCXInfoAlert>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} sm={12} md={6} lg={6}>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalSelectAplicacaoMercadoriaAutocomplete
              id="select-field-aplicacao-mercadoria"
              key="select-field-aplicacao-mercadoria"
              name="aplicacaoMercadoria"
              label={t('com.muralis.qcx.mercadoria.aplicacaoMercadoria')}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXSelectCondicaoVendaAutocomplete
              id="select-field-local-condicao-mercadoria"
              key="select-field-local-condicao-mercadoria"
              name="condicaoMercadoria"
              label={t('com.muralis.qcx.mercadoria.condicaoMercadoria')}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalTextField
              id="text-medida-estatistica-field"
              key="text-medida-estatistica-field"
              name="ncm.unidadeDeMedida.description"
              label={t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica')}
              disabled
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalTextField
              id="text-field-destaque-ncm"
              key="text-field-destaque-ncm"
              name="destaqueNcm"
              label={t('com.muralis.qcx.NCM.destaqueNCM')}
              disabled={isConsult || isSubConsult || isSubNone}
              maxLength={3}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalNumericDecimalField
              id="numeric-decimal-peso-liquido-field"
              key="numeric-decimal-peso-liquido-field"
              name="pesoLiquido"
              label={t('com.muralis.qcx.pesoLiquido')}
              disabled={isConsult || isSubConsult || isSubNone}
              scale={5}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalNumericDecimalField
              id="numeric-decimal-peso-bruto-field"
              key="numeric-decimal-peso-bruto-field"
              name="pesoBruto"
              label={t('com.muralis.qcx.pesoBruto')}
              disabled={isConsult || isSubConsult || isSubNone}
              scale={5}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <QCXSelectCFOPOperacoesFiscaisAutocomplete
              id="text-cfop-operacoes-fiscais-field"
              key="text-cfop-operacoes-fiscais-field"
              name="operacaoFiscal"
              label={t('com.muralis.qcx.CFOP.labelExtenso')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXSelectTipoDocumentoVinculadoAutocomplete
              id="select-field-tipo-documento-vinculado"
              key="select-field-tipo-documento-vinculado"
              name="tipoDocumentoVinculado"
              label={t('com.muralis.qcx.documento.tipoDocumentoVinculado')}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>
          <Grid
            item
            style={{
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalTextField
              id="text-field-numero-documento-vinculado"
              key="text-field-destaque-ncm"
              name="numeroDocumentoVinculado"
              label={t('com.muralis.qcx.documento.numeroDocumentoVinculado')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Seção de três Colunas */}
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={4}>
          <QCXSelectMoedaAutocomplete
            id="select-field-moeda-negociada"
            key="select-field-moeda-negociada"
            name="moeda.id"
            label="Moeda MLE"
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-condicao-venda-field"
            key="currency-valor-condicao-venda-field"
            name="valorMleMoeda"
            label="Valor Total MLE na Moeda"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-condicao-venda-field"
            key="currency-valor-condicao-venda-field"
            name="valorMleReal"
            label="Valor Total MLE em Reais"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4}>
          {!despesaNaCapa && (
            <QCXSelectMoedaAutocomplete
              id="select-field-moeda-despesa"
              key="select-field-moeda-despesa"
              name="moedaDespesa.id"
              label={t('com.muralis.qcx.moeda.moedaDespesas')}
              disabled={despesaNaCapa || isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {!despesaNaCapa && (
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-despesas-moeda-field"
              key="currency-valor-despesas-moeda-field"
              name="valorDespesaMoeda"
              label="Valor das Despesas na Moeda"
              disabled={despesaNaCapa || isConsult || isSubConsult || isSubNone}
              submitSucceeded={submitSucceeded}
              scale={7}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-despesas-field"
            key="currency-valor-despesas-field"
            name="valorDespesaReal"
            label="Valor das Despesas em Reais"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-condicao-venda-field"
            key="currency-valor-condicao-venda-field"
            name="valorFreteMoeda"
            label={t('com.muralis.qcx.frete.valorTotalFreteInternacional')}
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-condicao-venda-field"
            key="currency-valor-condicao-venda-field"
            name="valorFreteReal"
            label="Valor Frete Internacional em Reais"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-seguro-internacional-field"
            key="currency-valor-seguro-internacional-field"
            name="valorSeguroMoeda"
            label="Valor Seguro na Moeda"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-seguro-internacional-field"
            key="currency-valor-seguro-internacional-field"
            name="valorSeguroReal"
            label="Valor Seguro Internacional em Reais"
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={4}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-cif-field"
            key="currency-valor-cif-field"
            name="ii.baseCalculoReal"
            label={t('com.muralis.qcx.moeda.valorTotalCIF')}
            disabled
            submitSucceeded={submitSucceeded}
            scale={7}
          />
        </Grid>
      </Grid>

      {!isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_DUIMP]) && (
        <>
          <Grid item xs={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.NVE.NVEatributos')} />
          </Grid>
          <QCXFinalAtributoNveBondManager {...atributosNveProps} />
        </>
      )}

      {shouldShowDe(values) ? (
        <>
          <Grid item xs={12}>
            <QCXFormSubtitle title="Declaração de Exportação Extrangeira" />
          </Grid>
          <QCXFinalBondManagerOld
            isParentConsult={isConsult}
            listProps={exportacaoEstrangeiraListProps}
            formProps={exportacaoEstrangeiraFormProps}
            reducerConfig={exportacaoEstrangeiraReducerConfig}
            handleAdd={handleAddExportacaoExt}
            handleUpdate={handleUpdateExportacaoExt}
            handleAlreadyExists={handleExportacaoAlreadyExists}
            disableMultipleFields
          >
            <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
              <QCXFinalTextField
                id="numeric-integer-de-number-field"
                key="numeric-integer-de-number-field"
                name="ignorableFields.de.numero"
                label="Número da DE"
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.customGridFields} xs={12} sm={12} md={2} lg={2}>
              <QCXFinalNumericIntegerField
                id="numeric-integer-de-start-field"
                key="numeric-integer-de-start-field"
                name="ignorableFields.de.inicio"
                label="Início"
                allowNegative={false}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.customGridFields} xs={12} sm={12} md={2} lg={2}>
              <QCXFinalNumericIntegerField
                id="numeric-integer-de-end-field"
                key="numeric-integer-de-end-field"
                name="ignorableFields.de.fim"
                label="Fim"
                allowNegative={false}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
              <QCXSelectCertificadoAutocomplete
                label="Certificado Mercosul"
                name="ignorableFields.de.certificado"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.customGridFields} xs={12} sm={12} md={1} lg={1}>
              <QCXSelectPaisAutocomplete
                label="Pais"
                name="ignorableFields.de.pais"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={3} lg={3}>
              <QCXFinalTextField
                id="numeric-integer-de-numCertificado-field"
                key="numeric-integer-de-numCertificado-field"
                name="ignorableFields.de.numCertificado"
                label="Número Certificado"
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={1} lg={1}>
              <QCXFinalNumericIntegerField
                id="numeric-integer-de-item-field"
                key="numeric-integer-de-item-field"
                name="ignorableFields.de.item"
                label="Item"
                allowNegative={false}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={3} lg={3}>
              <QCXFinalNumericDecimalField
                id="numeric-integer-de-qtdUnidadeEstatistica-field"
                key="numeric-integer-de-qtdUnidadeEstatistica-field"
                name="ignorableFields.de.qtdUnidadeEstatistica"
                label="Qtd Unid Estatística"
                allowNegative={false}
                disabled={isConsult}
                scale={7}
              />
            </Grid>
          </QCXFinalBondManagerOld>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default MercadoriaAdicaoForm;
