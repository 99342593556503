import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectNumeroPoEasy({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'NUMERO_CONHECIMENTO',
        label: 'Número do Conhecimento',
        value: 'NUMERO_CONHECIMENTO',
      },
      {
        id: 'NUMERO_FATURA',
        label: 'Número da Fatura',
        value: 'NUMERO_FATURA',
      },
      {
        id: 'NUMERO_PROCESSO',
        label: 'Número do Processo',
        value: 'NUMERO_PROCESSO',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectNumeroPoEasy;
