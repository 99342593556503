import PageLayout from "../../../common/layouts/pageLayout";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from "react-router";
import { Button, FormControl, Grid, TextField, MenuItem, Select, InputLabel, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { useCallback, useState } from "react";
import { makeStyles } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from "../../../../features/feedback/feedbackSlice";
import { generateSpreadsheetLivroContas } from "./relatorioLivrocontasPage.helpers";
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import { SelectChangeEvent } from '@mui/material';

import {
    selectUnidadeSelecionada,
    selectUnidadesDeNegocioAssociadas
} from '../../../../features/usuario-logado/usuarioLogadoSlice';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        gap: '10px',
    },
    input: {
        width: '50%',
        marginRight: '1px',
        borderRadius: '4px',
    },
    button: {
        borderRadius: '4px',
    },
});

const RelatorioLivroContasPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const { token } = keycloak;

    keycloak.updateToken(300);

    const selectedUnit = useSelector(selectUnidadeSelecionada);
    const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);

    const [datas, setDatas] = useState({
        dataInicio: '',
        dataFim: '',
    });
    useUnidadeNegocioGuard();

    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>([]);
    const [selectedFornecedores, setSelectedFornecedores] = useState<string[]>([]); // Fornecedores multi-select
    const [origem, setOrigem] = useState('');

    const validarDatas = (datas: { dataInicio: string, dataFim: string }): boolean => {
        if (!datas.dataInicio || !datas.dataFim) {
            return false;
        }
        const dataInicio = new Date(datas.dataInicio);
        const dataFim = new Date(datas.dataFim);
        return dataInicio <= dataFim;
    };

    const handleChangeDataInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatas((prevDatas) => ({ ...prevDatas, dataInicio: e.target.value }));
    };

    const handleChangeDataFim = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatas((prevDatas) => ({ ...prevDatas, dataFim: e.target.value }));
    };

    const handleBusinessChange = (event: SelectChangeEvent<string | string[]>) => {
        const value = event.target.value as string[];
        setSelectedBusinessUnits(value);
    };

    const handleFornecedorChange = (event: SelectChangeEvent<string | string[]>) => {
        const value = event.target.value as string[];
        setSelectedFornecedores(value);
    };

    const handleOrigemChange = (event: SelectChangeEvent<string>) => {
        setOrigem(event.target.value);
    };

    const handleSubmit = useCallback(async () => {
        keycloak.updateToken(300);

        if (!validarDatas(datas)) {
            dispatch(setErrorFeedback({
                message: 'Preencha as datas de início e fim corretamente.',
            }));
            return;
        }

        if (token && selectedUnit) {
            try {
                const error = await generateSpreadsheetLivroContas(
                    datas.dataInicio,
                    datas.dataFim,
                    token,
                    selectedUnit.id,                  // Tenant
                    selectedBusinessUnits,             // Business Units
                    selectedFornecedores,              // Fornecedores
                    origem                             // Origem
                );
                if (error != null) {
                    dispatch(setErrorFeedback({
                        message: error ? error : 'Erro ao gerar a planilha.',
                    }));
                } else {
                    dispatch(setSuccessFeedback({
                        message: 'Planilha gerada com sucesso!',
                    }));
                }
            } catch (error) {
                dispatch(setErrorFeedback({
                    message: 'Erro inesperado ao gerar o relatório.',
                }));
            }
        }
        keycloak.updateToken(300);
        history.push("/relatorios");
    }, [datas, token, selectedUnit, selectedBusinessUnits, selectedFornecedores, origem, dispatch, keycloak, history]);

    return (
        <PageLayout
            title="Relatório Livro Contas"
            icon={<InsertDriveFileIcon color={'secondary'} />}>
            <Grid container spacing={2}>
                {/* Unidade de Negócio multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Unidade de Negócio</InputLabel>
                        <Select
                            multiple
                            value={selectedBusinessUnits}
                            onChange={handleBusinessChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {businessUnits.map((business: { id: string, pessoa: { nomeResumido: string } }) => (
                                <MenuItem key={business.id} value={business.id}>
                                    <Checkbox checked={selectedBusinessUnits.indexOf(business.id) > -1} />
                                    <ListItemText primary={business.pessoa.nomeResumido} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Fornecedor multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Fornecedor</InputLabel>
                        <Select
                            multiple
                            value={selectedFornecedores}
                            onChange={handleFornecedorChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {/* Replace these with actual Fornecedor options */}
                            <MenuItem value="1">
                                <Checkbox checked={selectedFornecedores.indexOf('1') > -1} />
                                <ListItemText primary="Fornecedor 1" />
                            </MenuItem>
                            <MenuItem value="2">
                                <Checkbox checked={selectedFornecedores.indexOf('2') > -1} />
                                <ListItemText primary="Fornecedor 2" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Origem select */}
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Origem</InputLabel>
                        <Select value={origem} onChange={handleOrigemChange}>
                            <MenuItem value="NUMERARIO">NUMERARIO</MenuItem>
                            <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                            <MenuItem value="ADMINISTRATIVO">ADMINISTRATIVO</MenuItem>
                            <MenuItem value="FATURAMENTO">FATURAMENTO</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Date fields */}
                <Grid item xs={12} style={{ margin: '25px 0 0 0' }}>
                    <div className={classes.container}>
                        <TextField
                            color='primary'
                            label="Data de Início"
                            type="date"
                            value={datas.dataInicio}
                            onChange={handleChangeDataInicio}
                            InputLabelProps={{ shrink: true }}
                            className={classes.input}
                            required
                        />
                        <TextField
                            color='primary'
                            label="Data de Fim"
                            type="date"
                            value={datas.dataFim}
                            onChange={handleChangeDataFim}
                            InputLabelProps={{ shrink: true }}
                            className={classes.input}
                            required
                        />
                    </div>
                </Grid>

                {/* Submit button */}
                <Grid item sm={4} style={{ margin: '25px 0 0 0' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        fullWidth
                        disabled={datas.dataFim === '' || datas.dataInicio === '' || !selectedBusinessUnits.length }>
                        Gerar Relatório
                    </Button>
                </Grid>
            </Grid>
        </PageLayout>
    );
};

export default RelatorioLivroContasPage;
