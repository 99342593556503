import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { DANFE_ENDPOINT, FILTER_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([DANFE_ENDPOINT]);
}

async function fetchById(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, id]);
}

async function register(data, queryParams = []) {
  return doPostQCXRequest([DANFE_ENDPOINT], data, queryParams);
}

async function save(data, queryParams = []) {
  return doPutQCXRequest([DANFE_ENDPOINT], data, queryParams);
}

async function send(id) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'send-danfe', id]);
}

async function assinar(id) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'assinar-danfe', id]);
}

async function activateById(id) {
  return doPutQCXActivateRequest([DANFE_ENDPOINT], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([DANFE_ENDPOINT], id);
}

async function printDanfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'print-danfe', id]);
}

async function baixarXmlNfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'download-xml', id], [], {
    responseType: 'blob',
  });
}

async function baixarPdfDanfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'print-danfe', id], [], {
    responseType: 'blob',
  });
}

async function validateDanfeStepOne(data, queryParams = []) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'validate-danfe-step-one'], data, queryParams);
}

async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([DANFE_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

async function fetchPaginateAsync(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([DANFE_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedParams);
  }
  return { data: [] };
}

async function consultaStatusNfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'danfe-status', id]);
}

async function fetchNotasDanfeByProcesso(params = []) {
  const validatedParams = validateQueryParams(params);

  return doGetQCXRequest([DANFE_ENDPOINT, 'find-notas'], validatedParams);
}

async function baixarXmlNfeLote(data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'download-xml', 'lote'], data, [], {
    responseType: 'blob',
  });
}

export {
  fetchAll,
  fetchById,
  register,
  save,
  send,
  activateById,
  inactivateById,
  printDanfe,
  baixarXmlNfe,
  baixarPdfDanfe,
  validateDanfeStepOne,
  fetchByFilter,
  fetchPaginateAsync,
  consultaStatusNfe,
  fetchNotasDanfeByProcesso,
  assinar,
  baixarXmlNfeLote,
};
