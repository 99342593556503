import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import QCXSelectTipoMascaraAutocomplete from '../select-tipo-mascara-follow-up/QCXSelectTipoMascaraAutocomplete';
import QCXSimpleMaskPreviewer from '../mask-previewer/QCXSimpleMaskPreviewer';
import {
  isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno,
  isTipoMascaraManual,
  isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraSequencialTracoAno,
  isTipoMascaraSiglaProdutoSiglaUnidadeSequencial,
  isTipoMascaraSiglaServicoSequencialBarraAno,
  isTipoMascaraSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraSiglaServicoTracoSequencialTracoAno,
} from '../select-tipo-mascara-follow-up/tipoMascaraFollowUpUtils';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericIntegerField from '../final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXHelperText from '../helper-text/QCXHelperText';
import QCXInfoAlert from '../alert/QCXInfoAlert';

const useStyles = makeStyles((theme) => ({
  gridPreview: {
    paddingLeft: '8px',
  },
  previewInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  previewInfoItem: {
    display: 'flex',
    paddingRight: '4px',
    color: theme.palette.grey[600],
  },
}));

export default function QCXConfiguracaoMascaraPreviewerFormGroup({
  subtitleProps = {},
  fieldProps,
  maskPreviewerProps = {},
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const currentValue = useMemo(() => _.get(values, fieldProps.tipoMascara.name), [fieldProps, values]);

  const previewMask = useMemo(() => {
    if (isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno(currentValue)) {
      return {
        value: '7550012345121',
        parts: [
          t('com.muralis.qcx.partesViaTransporteMascara.codigoViaTransporteParte'),
          t('com.muralis.qcx.partesViaTransporteMascara.siglaUnidadeNegocioParte'),
          t('com.muralis.qcx.partesViaTransporteMascara.sequenciaParte'),
          t('com.muralis.qcx.partesViaTransporteMascara.anoParte'),
        ],
      };
    }

    if (isTipoMascaraSiglaServicoTracoSequencialTracoAno(currentValue)) {
      return {
        value: t('com.muralis.qcx.partesSiglaServicoMascara.dia'),
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaServico'),
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
          t('com.muralis.qcx.partesSiglaServicoMascara.ano'),
        ],
      };
    }

    if (isTipoMascaraSequencialTracoAno(currentValue)) {
      return {
        value: '00001-21',
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
          t('com.muralis.qcx.partesSiglaServicoMascara.ano'),
        ],
      };
    }

    if (isTipoMascaraSiglaServicoTracoSequencialBarraAno(currentValue)) {
      return {
        value: 'IA-000001/21',
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaServicoIA'),
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
          t('com.muralis.qcx.partesSiglaServicoMascara.ano'),
        ],
      };
    }

    if (isTipoMascaraSiglaServicoSequencialBarraAno(currentValue)) {
      return {
        value: 'IA00001/21',
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaServicoIA'),
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
          t('com.muralis.qcx.partesSiglaServicoMascara.ano'),
        ],
      };
    }

    if (isTipoMascaraSiglaProdutoSiglaUnidadeSequencial(currentValue)) {
      return {
        value: '1100001',
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.produto'),
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaUnidadeNegocio'),
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
        ],
      };
    }

    if (isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno(currentValue)) {
      return {
        value: '11IA-000001/21',
        parts: [
          t('com.muralis.qcx.partesSiglaServicoMascara.produto'),
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaUnidadeNegocio'),
          t('com.muralis.qcx.partesSiglaServicoMascara.siglaServicoIA'),
          t('com.muralis.qcx.partesSiglaServicoMascara.sequencia'),
          t('com.muralis.qcx.partesSiglaServicoMascara.ano'),
        ],
      };
    }

    return undefined;
  }, [currentValue]);

  return (
    <>
      <Grid
        item
        style={{
          paddingTop: '2rem',
        }}
        xs={12}
      >
        <QCXFormSubtitle title={t('com.muralis.qcx.configuracaoMascaraProcessoFollowUp')} {...subtitleProps} />
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <QCXSelectTipoMascaraAutocomplete initialValues={values} {...fieldProps.tipoMascara} />
      </Grid>
      {currentValue && !isTipoMascaraManual(currentValue) && (
        <>
          <Grid item xs={12} sm={4} md={4}>
            <QCXFinalNumericIntegerField {...fieldProps.tamanhoSequencial} />
          </Grid>
          <Grid item xs={12}>
            <QCXFinalCheckboxField {...fieldProps.reiniciaContagemAnualmente} />
          </Grid>
          {isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno(currentValue) && (
            <Grid item xs={12}>
              <QCXFinalCheckboxField {...fieldProps.reiniciaContagemTipoProduto} />
            </Grid>
          )}
          {(isTipoMascaraSiglaServicoTracoSequencialBarraAno(currentValue)  ||
            isTipoMascaraSiglaServicoTracoSequencialTracoAno(currentValue)) && (
            <Grid item xs={12}>
              <QCXFinalCheckboxField {...fieldProps.reiniciaContagemTipoServico} />
            </Grid>
          )}
          {(isTipoMascaraSiglaServicoTracoSequencialBarraAno(currentValue)  ||
            isTipoMascaraSiglaServicoTracoSequencialTracoAno(currentValue)  ||
            isTipoMascaraSiglaServicoSequencialBarraAno(currentValue))      && (
            <Grid item xs={12}>
              <QCXFinalCheckboxField {...fieldProps.mesmaSequenciaPorGrupoAssociado} />
            </Grid>
          )}
        </>
      )}

      <Grid item container className={classes.gridPreview} xs={12} sm={12} md={8} alignContent="flex-start">
        {!previewMask && isTipoMascaraManual(currentValue) && (
          <Grid item xs={12}>
            <QCXInfoAlert>{t('com.muralis.qcx.mensagem.numeroReferenciaProcesso')}</QCXInfoAlert>
          </Grid>
        )}
        {previewMask && (
          <>
            <Grid item xs={12}>
              <QCXSimpleMaskPreviewer previewOptions={previewMask} {...maskPreviewerProps} />
            </Grid>
            <Grid item xs={12}>
              <QCXHelperText>{t('com.muralis.qcx.mensagem.valoresIlustrativos')}</QCXHelperText>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
