/* eslint-disable indent */
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Cancel as CancelIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import _, { isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CLOSED_STATUS, DONE_STATUS, FAIL_STATUS, SUBMITTING_STATUS } from '../../../../utils/hooks/form/dialog/formDialogUtils';

import QCXRelatoriosContasDialogForm from '../../../../components/contas-pagar-receber/QCXRelatoriosContasDialogForm';
import QCXLink from '../../../../components/link/QCXLink';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import ContasPagarContext from '../../../../contexts/financial/contas-pagar/ContasPagarContext';
import { selectConciliacoesBancarias } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSelectors';
import { fetchConciliacaoPendenteAllAsync } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaThunks';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { contasPagarAPI } from '../../../../features/contas-pagar/contasPagarAPI';
import {
  selectContasPagar,
  selectMode,
  selectRefresh,
  selectRelatedContasPagarItemModel,
  selectRelatedReportModel,
  selectRelatedSelectionContasPagarList,
} from '../../../../features/contas-pagar/contasPagarSelectors';
import {
  changeToBatchApprovalMode,
  changeToConsultMode,
  failure,
  loading,
  refresh as refreshAction,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedSelectionContasPagarList,
  setRelatedContasPagarItemModel,
  setRelatedReportModel,
  setRelatedSelectionContasPagarList,
  success,
} from '../../../../features/contas-pagar/contasPagarSlice';
import { fetchAllAsync, generateReportAPagarByDateAsync } from '../../../../features/contas-pagar/contasPagarThunks';
import { estadoActions } from '../../../../features/estado/estadoSlice';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXWarningAlert from '../../../../shared-components/alert/QCXWarningAlert';
import QCXJustifiedActionFormDialog from '../../../../shared-components/dialog/QCXJustifiedActionFormDialog';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import contasPagarUtils from '../../../../utils/general/contas-pagar/ContasPagarUtils';
import { formatDate, normalizeData, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import {
  isAlternativeLoadingStatus,
  isApprovalBatchMode,
  isNoneMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import QCXConfirmPaymentDateForm from '../../../../components/contas-pagar-receber/QCXConfirmPaymentDateForm';

export default function ContasPagarConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const { status } = useContext(ContasPagarContext);

  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const contasPagar = useSelector(selectContasPagar);
  const conciliacoesBancariasPendentes = useSelector(selectConciliacoesBancarias);
  const relatedContasPagarItem = useSelector(selectRelatedContasPagarItemModel);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const relatedSelectionContasPagarList = useSelector(selectRelatedSelectionContasPagarList);

  const isConciliacoesBancariasPendentes = useMemo(
    () => conciliacoesBancariasPendentes.length > 0,
    [conciliacoesBancariasPendentes]
  );

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const requestConciliacaoBancariaPendente = useCallback(async () => {
    dispatch(fetchConciliacaoPendenteAllAsync());
  }, []);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionContasPagarList ||
      (_.isArrayLikeObject(relatedSelectionContasPagarList) && _.isEmpty(relatedSelectionContasPagarList)),
    [relatedSelectionContasPagarList]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    fetchAll();
    requestConciliacaoBancariaPendente();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionContasPagarList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionContasPagarList(selectionModel));
      }
    },
    [contasPagar, isApprovalBatch]
  );

  const handleConfirmBatchApproval = useCallback(
    async (event) => {
      try {
        dispatch(loading());
  
        const dataPagamento = event?.dataPagamento;
        console.log(contasPagar);
  
        const contasPagarFiltrado = contasPagar.map((conta) => ({
          id: conta?.id,
          contaBancaria: conta?.contaBancaria?.id,
          valor: conta?.valor,
          solicitante: conta?.solicitante?.emailSolicitante,
          followUpId: conta?.followUp?.id,
          despesaReceitaId: conta?.despesaReceita?.id,
          origem: conta?.origem,
          tipo: conta?.tipo,
          contaComissaria: conta?.contaBancaria?.controlarSaldo,
        }));
  
        const selectionModelContasPagarList = relatedSelectionContasPagarList.map((itemModel) => {
          const [contasPagarSelecionadas] = contasPagarFiltrado.filter((conta) => conta?.id === itemModel);
          return contasPagarSelecionadas;
        });
  
        const idsContasSelecionadasList = selectionModelContasPagarList.map((conta) => ({
          id: conta?.id,
        }));
  
        const contasPagasSelecionadasTamanho = selectionModelContasPagarList.length;
        const contasPagasSelecionadasLastItem = selectionModelContasPagarList[contasPagasSelecionadasTamanho - 1];
        const { contaBancaria } = contasPagasSelecionadasLastItem;
  
        const somaValoresPagar = selectionModelContasPagarList.reduce(
          (acumulador, objetoAtual) => acumulador + objetoAtual.valor,
          0
        );
  
        const payload = [
          {
            conta: {
              tipo: {
                description: contasPagarUtils.TITULO_TRANSFERENCIA,
              },
              proprietario: {
                id: contaBancaria,
              },
            },
            contaBancariaId: contaBancaria,
            referencias: idsContasSelecionadasList,
            operacao: {
              description: contasPagarUtils.TRANSFERENCIA,
            },
            valor: somaValoresPagar,
            dataPagamento,
            solicitante: contasPagasSelecionadasLastItem?.solicitante,
            followUpId: contasPagasSelecionadasLastItem?.followUpId,
            despesaReceitaId: contasPagasSelecionadasLastItem?.despesaReceitaId,
            origem: contasPagasSelecionadasLastItem?.origem,
            tipo: contasPagasSelecionadasLastItem?.tipo,
            contaComissaria: contasPagasSelecionadasLastItem?.contaComissaria,
          },
        ];
  
        console.log(payload);
  
        const response = await contasPagarAPI.transferencia(payload);
  
        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.loteTransferenciaContasPagar');
  
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
          dispatch(resetMode());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.transferirLoteContasPagarEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.transferirContasPagarLote');
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [contasPagar, relatedSelectionContasPagarList]
  );

  const handleCancelBatchApproval = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: contasPagarUtils.CONTAS_PAGAR,
          })
        );
      }
    },
    []
  );

  const [handleConfirmBatchApprovalClick, formDialogConfirmBatchApprovalStatus, handleFormDialogonfirmBatchApprovalStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: contasPagarUtils.CONTAS_PAGAR_2,
          })
        );
      }
    },
    []
  );

  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const vencimentoDe = normalizeData(event?.data?.vencimentoDe);
    const vencimentoAte = normalizeData(event?.data?.vencimentoAte);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorioContas', {
          tipoRelatorio: t('com.muralis.qcx.financeiro.contasPagar'),
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportAPagarByDateAsync({
        vencimentoDe,
        vencimentoAte,
        handlers,
      })
    );

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };

  const [handleOpenCancel, dialogCancelStatus, handleConsultCancelStatus] = useFormDialogSync(async (event, data) => {
    if (_.isFunction(event?.stopPropagation)) {
      event.stopPropagation();
      dispatch(setRelatedContasPagarItemModel(data));
    }
  }, []);

  const handleCancelSubmit = useCallback(
    async ({ ...values }) => {
      try {
        dispatch(loading());
        handleConsultCancelStatus(SUBMITTING_STATUS);

        const response = await contasPagarAPI.cancelar({
          id: values?.id,
          justificativa: values?.justificativa,
        });

        if (response?.status === 200) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.contaPagarCancelada');

          handleConsultCancelStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.cancelarContasPagarEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.cancelarContasPagar');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
        handleConsultCancelStatus(FAIL_STATUS);
      }
    },
    [handleConsultCancelStatus]
  );

  const conciliacaoPendenteMessage = useCallback(
    () => (
      <>
        {isConciliacoesBancariasPendentes > 0 && (
          <QCXWarningAlert style={{ margin: '1rem 0' }} closable>
            <Trans i18nKey="com.muralis.qcx.mensagem.alertaConciliacoesPendentes">
              <QCXLink
                to={t('com.muralis.qcx.url.moduloFinanceiroConciliacaoBancaria')}
                style={{ fontWeight: 'bold', color: '#663C00', textDecoration: 'none' }}
              >
                Conciliação Bancária
              </QCXLink>
            </Trans>
          </QCXWarningAlert>
        )}
      </>
    ),
    [conciliacoesBancariasPendentes]
  );

  const configureDependencies = useCallback(() => {
    dispatch(estadoActions.changeControlTo(IDLE_CONTROL));
  }, []);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.financeiroContasPagarDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history, t]
  );

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.followUp?.numero || '-',
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 180,
        valueGetter: ({ row }) => row?.origem || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.insertionDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'aprovacao',
        headerName: t('com.muralis.qcx.aprovacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 180,
        valueGetter: ({ row }) => formatDate(row?.dataAprovacao, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'fornecedor',
        headerName: t('com.muralis.qcx.fornecedor.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 120,
        valueGetter: ({ row }) => row?.fornecedor?.pessoa?.nome || '-',
      },
      {
        field: 'solicitante',
        headerName: t('com.muralis.qcx.solicitante'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.solicitante?.nomeSolicitante || '-',
      },
      {
        field: 'valorPagar',
        headerName: t('com.muralis.qcx.valorPagar'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.valor, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) =>
          unnormalizeNumeral(
            contasPagarUtils.saldoContasPagar(row?.movimentacoes, row?.valor),
            formatBrazilianNumericDecimal(2)
          ) || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-contas-pagar">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                    disabled={isApprovalBatch}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id: row?.solicitacaoPagamentoId,
                          status,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenCancel(event, row);
                      }}
                      disabled={
                        contasPagarUtils.isPago(row?.status) ||
                        contasPagarUtils.isCancelado(row?.status) ||
                        TituloUtils.isOrigemTransferencia(row?.origem)
                      }
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, handleOpenCancel]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.contasPagar'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.contasPagar'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        others: [
          ...(isNone
            ? [
              {
                description: t('com.muralis.qcx.relatorios.label'),
                disabled: false,
                color: 'primary',
                onClick: handleImprimirClick,
              },
              {
                description: <CheckCircleIcon size={20} color="white" />,
                ...(_.isEmpty(contasPagar || [])
                  ? {
                    disabled: true,
                    color: 'disabled',
                  }
                  : {
                    disabled: false,
                    style: {
                      backgroundColor: 'green',
                    },
                  }),
                onClick: handleEnableBatchApprovalMode,
              },
            ]
            : []),
          ...(isApprovalBatch
            ? [
              {
                description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                startIcon: <CheckCircleIcon />,
                color: 'secondary',
                tooltip: {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                },
                onClick: handleConfirmBatchApprovalClick,
                disabled: isEmptySelectionList,
              },
              {
                description: <CancelOutlinedIcon />,
                variant: 'outlined',
                color: 'default',
                tooltip: {
                  description: t('com.muralis.qcx.acoes.cancelar'),
                },
                onClick: handleCancelBatchApproval,
              },
            ]
            : []),
        ],
      },
      table: {
        columns,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionContasPagarList,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (table) =>
          table?.row?.status === contasPagarUtils.PENDENTE && !TituloUtils.isOrigemTransferencia(table?.row?.origem),
      },
    }),
    [
      breadcrumbs,
      columns,
      isApprovalBatch,
      isEmptySelectionList,
      contasPagar,
      handleCancelBatchApproval,
      handleConfirmBatchApproval,
      handleSelectionModelChange,
      handleEnableBatchApprovalMode,
      relatedSelectionContasPagarList,
    ]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={contasPagar}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['conta-pagar']}
      alertMessage={conciliacaoPendenteMessage()}
      clearSelection
    >
      <QCXJustifiedActionFormDialog
        handleSubmit={handleCancelSubmit}
        status={dialogCancelStatus}
        handleStatus={handleConsultCancelStatus}
        type={contasPagarUtils.CANCELAR}
        initialValues={relatedContasPagarItem}
      />
      <QCXRelatoriosContasDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={(event) => handleImprimirByDateSubmit(event)}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
        typeReport={contasPagarUtils.CONTAS_PAGAR}
      />

      <QCXConfirmPaymentDateForm
        handleFormStatus={handleFormDialogonfirmBatchApprovalStatus}
        handleOnSubmit={(event) => handleConfirmBatchApproval(event)}
        formDialogStatus={formDialogConfirmBatchApprovalStatus}
        initialValues={reportRelatedModel}
      />

    </QCXSimpleConsultPageTemplate>
  );
}
