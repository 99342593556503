import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function QCXSelectTipooBaseReduzida({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'BASE_REDUZIDA_DI',
        label: 'Base Reduzida DI',
        value: 'BASE_REDUZIDA_DI',
      },
      {
        id: 'DANFE_ALIQUOTA',
        label: 'Danfe Alíquota',
        value: 'DANFE_ALIQUOTA',
      },
      {
        id: 'DANFE_BASE',
        label: 'Danfe Base',
        value: 'DANFE_BASE',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default QCXSelectTipooBaseReduzida;
