import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_CONTAS_RECEBER!;  // Update the endpoint for Contas a Receber

export const generateContasAReceberReport = async (
    token: string,
    unidadeId: string,  // Updated to receive the selected unit ID
    tenantIds: string[],  // Receive only the IDs of the tenants
    clientIds: string[]   // Receive only the IDs of the clients
): Promise<string | null> => {

    const params = new URLSearchParams();
    params.set('tenantId', tenantIds.join(','));  // Set tenant IDs in the params
    if (clientIds.length > 0) {
        params.set('clientesId', clientIds.join(','));  // Set client IDs if provided
    }

    try {
        const response = await axios.get(
            `${baseUrl}?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",  
                    quickcomexTenant: unidadeId,
                },
                responseType: "blob",
            }
        );

        if (response.status === 200) {
            const file = new File([response.data], "relatorioContasAReceber.xlsx", {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            a.click();
        } else {
            return await response.data.text();
        }
    } catch (error) {
        return axios.isAxiosError(error) ? "Erro inesperado" : "Erro ao gerar o relatório.";
    }

    return null;
};
