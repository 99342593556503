import ExcelJS from 'exceljs';
import {
  DraftDanfeFaxeReportItem,
  ReportItemsSumsFaxe,
} from './relatorioDraftDanfeFaxePage.types';
import saveAs from 'file-saver';

const headers = [
  'numero_danfe',
  'UN',
  'quantidade',
  'valor_unitario',
  'valor_total_mercadoria',
  'ato_concessorio',
  'bc_icms',
  'aliquota_icms',
  'valor_icms',
  'bc_ii',
  'aliquota_ii',
  'valor_ii',
  'bc_ipi',
  'aliquota_ipi',
  'valor_ipi',
  'bc_pis',
  'aliquota_pis',
  'valor_pis',
  'bc_cofins',
  'aliquota_cofins',
  'valor_cofins',
  'outras_despesas',
  'ncm',
  'lote',
  'cfop',
  'planta',
  'di',
  'adicao',
  'item',
  'fabricante',
  'icms_other_base',
  'icms_excluded_base',
  'ipi_other_base',
  'ipi_excluded_base',
  'pis_other_base',
  'pis_excluded_base',
  'observacoes',
  'exportador',
  'codigo_exportador',
  'pais',
  'n_do_lote',
  'data_fabricacao',
  'data_validade',
  'data_di',
  'data_desembaraco',
  'fob',
  'frete_total',
  'ato_concessorio',
  'nve',
  'local_desembaraco',
  'transportadora',
  'peso_bruto_total',
  'peso_liquido_total',
  'quantidade_volumes',
  'especie',
  'numero_container',
  'referencia_despachante',
  'PO',
  'numero_da_di',
  'taxa_dolar',
  'valor_cif',
  'imp_importacao',
  'pisnull',
  'cofinsnull',
  'taxa_siscomex',
  'marinha_mercante',
];

const mainTableHeaders = [
  'COD. PRODUTO',
  'PRODUTO', '', '', // PRODUTO occupies 3 columns
  'UN',
  'QUANTIDADE',
  'VALOR UNITÁRIO',
  'VALOR TOTAL DA MERCADORIA',
  'ATO CONCESSÓRIO',
  'BC ICMS',
  'ALÍQUOTA ICMS',
  'VALOR ICMS',
  'BC do II',
  'ALÍQUOTA I.I.',
  'VALOR II',
  'I.I. STATISTICAL FLAG',
  'BASE IPI',
  'ALÍQUOTA IPI',
  'VALOR IPI',
  'BASE PIS',
  'ALÍQUOTA PIS',
  'VALOR PIS',
  'BASE COFINS',
  'ALÍQUOTA COFINS',
  'VALOR COFINS',
  'OTHER COST',
  'NCM',
  'LOTE',
  'CFOP',
  'PLANTA',
  'ICMS TAX LAW',
  'IPI TAX LAW',
  'COFINS TAX LAW',
  'PIS TAX LAW',
  'DI',
  'ADIÇÃO',
  'SEQ ADIÇÃO',
  'FABRICANTE',
  'ICMS OTHER BASE',
  'ICMS EXCLUDED BASE',
  'IPI OTHER BASE',
  'IPI EXCLUDED BASE',
  'PIS OTHER BASE',
  'PIS EXCLUDED BASE',
  'COFINS OTHER BASE',
  'COFINS EXCLUDED BASE',
];

/**
 * Group items by DANFE number.
 * @param {DraftDanfeFaxeReportItem[]} items - DraftDanfeFaxeReportItem[]
 * @returns {DraftDanfeFaxeReportItem[][]}
 */
export const groupItemsByDanfeFaxe = (items: DraftDanfeFaxeReportItem[]) => {
  const groupedItems: { [key: number]: DraftDanfeFaxeReportItem[] } = {};
  items.forEach((item) => {
    if (!groupedItems[item.numero_danfe]) {
      groupedItems[item.numero_danfe] = [];
    }
    groupedItems[item.numero_danfe].push(item);
  });
  return Object.values(groupedItems);
};

/**
 * Calculate the sum of the numeric fields in a group.
 * @param {DraftDanfeFaxeReportItem[]} group - DraftDanfeFaxeReportItem[]
 * @returns {ReportItemsSumsFaxe}
 */
const calculateGroupSumsFaxe = (group: DraftDanfeFaxeReportItem[]) => {
  const sums: ReportItemsSumsFaxe = {
    quantidades: 0,
    valorTotalMercadoria: 0,
    fretes: 0,
    valoresII: 0,
    valoresIPI: 0,
    valoresPIS: 0,
    valoresCOFINS: 0,
    outrasDespesas: 0,
    pesosBrutos: 0,
    pesosLiquidos: 0,
    valoresCIF: 0,
    valoresAFRMM: 0,
  };

  group.forEach((item) => {
    sums.quantidades += item.quantidade;
    sums.valorTotalMercadoria += item.valor_total_mercadoria;
    sums.fretes += item.frete_total;
    sums.valoresII += item.valor_ii;
    sums.valoresIPI += item.valor_ipi;
    sums.valoresPIS += item.valor_pis;
    sums.valoresCOFINS += item.valor_cofins;
    sums.outrasDespesas += item.outras_despesas;
    sums.pesosBrutos += item.peso_bruto_total;
    sums.pesosLiquidos += item.peso_liquido_total;
    sums.valoresCIF += item.valor_cif;
    sums.valoresAFRMM += item.marinha_mercante;
  });

  return sums;
};

/**
 * Generate an Excel spreadsheet from the grouped data.
 * @param {DraftDanfeFaxeReportItem[][]} data - DraftDanfeFaxeReportItem[][]
 * @param {string | undefined} processo - string | undefined
 */
export const generateSpreadsheetFaxe = (
  data: DraftDanfeFaxeReportItem[][],
  processo: string | undefined
) => {
  const workbook = new ExcelJS.Workbook();

  data.forEach((group) => {
    const worksheet = workbook.addWorksheet(`Danfe ${group[0].numero_danfe}`);

    // Add the title row
    const title = "Draft Para Emissão de Danfe - Importação";
    worksheet.addRow([title]);

    // Merge the title across the necessary columns
    worksheet.mergeCells(`A1:${String.fromCharCode(65 + mainTableHeaders.length - 1)}1`);
    worksheet.getCell('A1').font = { bold: true, size: 16 };
    worksheet.getCell('A1').alignment = { horizontal: 'center' };

    // Add the main table headers
    const headerRow = worksheet.addRow(mainTableHeaders);

    // Add the row with column numbers
    const columnNumbers = mainTableHeaders.map((_, index) => index + 1);
    const numberRow = worksheet.addRow(columnNumbers);

    // Apply styling to headers with borders and no background color
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, size: 12 };
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF00008B' } },  // Dark Blue border
        bottom: { style: 'thin', color: { argb: 'FF00008B' } },
        left: { style: 'thin', color: { argb: 'FF00008B' } },
        right: { style: 'thin', color: { argb: 'FF00008B' } },
      };
    });

    // Apply borders to the row with column numbers
    numberRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF00008B' } },
        bottom: { style: 'thin', color: { argb: 'FF00008B' } },
        left: { style: 'thin', color: { argb: 'FF00008B' } },
        right: { style: 'thin', color: { argb: 'FF00008B' } },
      };
    });

    // Fill in the data for the main table
    group.forEach(item => {
      const row = worksheet.addRow([
        null, // COD. PRODUTO (not provided, so set to null)
        null, '', '', // PRODUTO (descricao_dos_produtos not provided, so set to null and span 3 columns)
        item.UN,
        item.quantidade,
        item.valor_unitario,
        item.valor_total_mercadoria,
        item.ato_concessorio,
        item.bc_icms,
        item.aliquota_icms,
        item.valor_icms,
        item.bc_ii,
        item.aliquota_ii,
        item.valor_ii,
        item.ipi_other_base,
        item.aliquota_ipi,
        item.valor_ipi,
        item.pis_other_base,
        item.aliquota_pis,
        item.valor_pis,
        item.cofins_other_base,
        item.aliquota_cofins,
        item.valor_cofins,
        item.outras_despesas,
        item.ncm,
        item.lote,
        item.cfop,
        item.planta,
        item.icms_other_base,
        item.icms_excluded_base,
        item.ipi_other_base,
        item.ipi_excluded_base,
        item.pis_other_base,
        item.pis_excluded_base,
        item.cofins_other_base,
        item.cofins_excluded_base,
      ]);

      // Apply borders to the main data cells
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF00008B' } },
          bottom: { style: 'thin', color: { argb: 'FF00008B' } },
          left: { style: 'thin', color: { argb: 'FF00008B' } },
          right: { style: 'thin', color: { argb: 'FF00008B' } },
        };
      });
    });

    // Auto-size columns and increase the width for all columns
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column && column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 20 ? 20 : maxLength;
      }
    });

    // Add the additional details table vertically, with Observações at the end
    const additionalDetailsData = [
      ['EXPORTADOR', group[0].exportador],
      ['CODIGO EXPORTADOR', group[0].codigo_exportador],
      ['PAÍS', group[0].pais],
      ['N° DO LOTE', group[0].n_do_lote],
      ['DATA DE FABRICAÇÃO', group[0].data_fabricacao],
      ['DATA DE VALIDADE', group[0].data_validade],
      ['DATA D.I.', group[0].data_di],
      ['DATA DESEMBARACO', group[0].data_desembaraco],
      ['FOB R$', group[0].fob],
      ['FRETE R$', group[0].frete_total],
      ['ATO CONCESSÓRIO', group[0].ato_concessorio],
      ['NVE', group[0].nve],
      ['LOCAL DESEMBARACO', group[0].local_desembaraco],
      ['TRANSPORTADORA', group[0].transportadora],
      ['PESO BRUTO', group[0].peso_bruto_total],
      ['PESO LIQUIDO', group[0].peso_liquido_total],
      ['VOLUMES', group[0].quantidade_volumes],
      ['ESPECIE', group[0].especie],
      ['NUMERO DO CONTAINER', group[0].numero_container],
      ['REF. DESPACHANTE', group[0].referencia_despachante],
      ['PO', group[0].PO],
      ['NUMERO DA DI', group[0].numero_da_di],
      ['TAXA DOLAR', group[0].taxa_dolar],
      ['VALOR CIF', group[0].valor_cif],
      ['IMP. IMPORTAÇÃO', group[0].imp_importacao],
      ['PIS (null%)', group[0].pisnull],
      ['COFINS (null%)', group[0].cofinsnull],
      ['TAXA SISCOMEX', group[0].taxa_siscomex],
      ['MARINHA MERCANTE', group[0].marinha_mercante],
      ['ATA AÉREO', 0], // Always 0
      ['Observações', group[0].observacoes] // Place Observações last
    ];

    additionalDetailsData.forEach((detail, index) => {
      const row = worksheet.addRow(detail);

      // Style only the header cells of the additional details
      if (index < additionalDetailsData.length) {
        row.getCell(1).font = { bold: true, size: 12 };
        row.getCell(1).alignment = { horizontal: 'center' };
        row.getCell(1).border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } }, // White border
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        // Remove borders from the value cells
        row.getCell(2).border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };
      } else {
        // Apply different formatting for the "Observações" value cell to make it bigger
        row.getCell(2).alignment = { wrapText: true }; // Allow text wrapping
        row.getCell(2).font = { bold: true, size: 14 };
      }
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), `Relatório Draft Danfe Faxe - ${processo}.xlsx`);
    });
  });
};
