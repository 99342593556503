import { ReponseType } from '../../utils/api/api-utils';
import { doGetQCXRequest, doPostQCXRequest, doPutQCXRequest } from '../../utils/api/domain/domain-api-utils';
import {
  ADICAO_ENDPOINT,
  CALCULO_ENDPOINT,
  DECLARACAO_IMPORTACAO_ENDPOINT,
  DI_ENDPOINT,
  FATURA_ENDPOINT,
  REPORT_ENDPOINT,
  ITENS_ENDPOINT,
  EMISSAO_ICMS_ENDPOINT,
  EMISSAO_GLME_SP_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function save(data, queryParams = []) {
  return doPutQCXRequest([FATURA_ENDPOINT, data?.id, DECLARACAO_IMPORTACAO_ENDPOINT], data, queryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([FATURA_ENDPOINT, id]);
}

async function generateReportById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, DI_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function emitirIcmsRio(data) {
  return doGetQCXRequest(
    [DECLARACAO_IMPORTACAO_ENDPOINT, EMISSAO_ICMS_ENDPOINT, data.id],
    [
      {
        name: 'dataPagamento',
        value: data.dataPagamento,
      },
    ]
  );
}

async function emitirGlmeSp(data) {
  return doPostQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, EMISSAO_GLME_SP_ENDPOINT, data.id]);
}

async function generateReportAdicoesById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, CALCULO_ENDPOINT, ADICAO_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function generateReportItensById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, ITENS_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function calcularDisLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'calcular', 'diduimp', 'lote'], data);
}

async function gerarXMLDiagnosticoLote(data, tipo) {
  const queryParams = [
    {
      name: 'tipo',
      value: tipo,
    },
  ];

  return doPutQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'xml-report', 'lotes'], data, queryParams, {
    responseType: 'blob',
  });
}

async function releaseDisEmLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'conferir', 'lote'], data);
}

const declaracaoImportacaoAPI = {
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
  emitirIcmsRio,
  emitirGlmeSp,
  gerarXMLDiagnosticoLote,
  calcularDisLote,
  releaseDisEmLote,
};

export {
  declaracaoImportacaoAPI,
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
  emitirIcmsRio,
  emitirGlmeSp,
  gerarXMLDiagnosticoLote,
  calcularDisLote,
  releaseDisEmLote,
};
