import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_DESPESAS_PAGAS!;

    
export const generateDespesasPagasReport = async (
    token: string,
    unidadeId: string,  
    tenantIds: string[],  
    startDate: Date,
    endDate: Date
): Promise<string | null> => {

    const params = new URLSearchParams();
    params.set('tenantId', tenantIds.join(',')); 
    params.set('startDate', startDate.toISOString().split('T')[0]);
    params.set('endDate', endDate.toISOString().split('T')[0]);

    try {
        const response = await axios.get(
            `${baseUrl}?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",  
                    quickcomexTenant: unidadeId,
                },
                responseType: "blob",
            }
        );

        if (response.status === 200) {
            const file = new File([response.data], "relatorioDespesasPagas.xlsx", {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            a.click();
        } else {
            return await response.data.text();
        }
    } catch (error) {
        return axios.isAxiosError(error) ? "Erro inesperado" : "Erro ao gerar o relatório.";
    }

    return null;
};
