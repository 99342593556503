import { Button, FormControlLabel, Modal, Radio, RadioGroup } from '@material-ui/core';

type CatalogoProdutosTransmitirModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  catalogoState: string;
  handleCatalogoStateChange: (event: any) => void;
  handleConfirm: () => void;
};

export default function CatalogoProdutosTransmitirModal({
  isModalOpen,
  closeModal,
  catalogoState,
  handleCatalogoStateChange,
  handleConfirm,
}: CatalogoProdutosTransmitirModalProps) {
  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <div
        style={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
        }}
      >
        <h2>Tipo de Transmissão</h2>
        <RadioGroup value={catalogoState} onChange={handleCatalogoStateChange}>
          <FormControlLabel value="ATIVADO" control={<Radio />} label="Ativado" />
          <FormControlLabel value="DESATIVADO" control={<Radio />} label="Desativado" />
          <FormControlLabel value="RASCUNHO" control={<Radio />} label="Rascunho" />
        </RadioGroup>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
