import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions,
  Checkbox,
  IconButton,
  Badge,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { aduaneirasNcmTecAPI } from '../../../features/aduaneiras-ncm-tec/AduaneirasNcmTecAPI';
import QCXButton from '../../../shared-components/button/QCXButton';
import QCXDataGrid from '../../../shared-components/data-grid/QCXDataGrid';
import LoadingIndicator from '../../../ts/common/components/loadingIndicator';
import QCXFormTitle from '../../form-title/QCXFormTitle';
import ExTarifarioPopup from './ExTarifarioPopup';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
      width: '80vw',
    },
    '& .MuiDataGrid-columnHeaderSortable .MuiDataGrid-sortIcon': {
      opacity: 0,
      display: 'none',
    },
    '& .MuiDataGrid-cellWithRenderer': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

function compareAliquotas(aliquota1, aliquota2) {
  const sanitizedAliquota1 = String(aliquota1)?.replace(',', '.');
  const sanitizedAliquota2 = String(aliquota2)?.replace(',', '.');

  return Number(sanitizedAliquota1) === Number(sanitizedAliquota2);
}

const PAGE_SIZE = 10;

const AuxiliarTributosPopup = ({ handleSubmit, adicoes, open, onClose }) => {
  const classes = useStyles();
  const form = useForm();
  const { values } = useFormState();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedAdicaoId, setSelectedAdicaoId] = useState('');
  const [isExTarifarioOpen, setIsExTarifarioOpen] = useState(false);
  const [aduaneirasNcmTec, setAduaneirasNcmTec] = useState([]);

  const [filterDifferent, setFilterDifferent] = useState(false);

  const [exTarifarioTipo, setExTarifarioTipo] = useState(null); // "ii" | "ipi";
  const [exTarifariosSelected, setExTarifariosSelected] = useState([]);

  const rows = adicoes.map((adicao) => {
    const aduaneiraNcmTec = aduaneirasNcmTec.find((a) => a.ncmCodigo === adicao?.ncm?.code);

    const exTarifariosII = aduaneiraNcmTec?.exTarifarios?.filter((exTarifario) => {
      const regex = /^Ex (\d{2,3})/;
      const match = exTarifario.ncmDescricao.match(regex);

      const iiDigitsAmount = 3;
      return match?.[1]?.length === iiDigitsAmount;
    });

    const exTarifariosIPI = aduaneiraNcmTec?.exTarifarios?.filter((exTarifario) => {
      const regex = /^Ex (\d{2,3})/;
      const match = exTarifario.ncmDescricao.match(regex);

      const ipiDigitsAmount = 2;
      return match?.[1]?.length === ipiDigitsAmount;
    });

    const ii = adicao?.ii?.aliquota ?? 0;
    const ipi = adicao?.ipi?.aliquota ?? 0;
    const pis = adicao?.pisCofins?.aliquotaPisPasep ?? 0;
    const cofins = adicao?.pisCofins?.aliquotaCofins ?? 0;

    return {
      ...adicao,
      iiOriginal: ii,
      iiTec: aduaneiraNcmTec?.ii,
      isIiDifferent: !compareAliquotas(ii, aduaneiraNcmTec?.ii),
      ipiOriginal: ipi,
      ipiTec: aduaneiraNcmTec?.ipi,
      isIpiDifferent: !compareAliquotas(ipi, aduaneiraNcmTec?.ipi),
      pisOriginal: pis,
      pisTec: aduaneiraNcmTec?.pisPasep,
      isPisDifferent: !compareAliquotas(pis, aduaneiraNcmTec?.pisPasep),
      cofinsOriginal: cofins,
      cofinsTec: aduaneiraNcmTec?.cofins,
      isCofinsDifferent: !compareAliquotas(cofins, aduaneiraNcmTec?.cofins),
      tipoIcms: aduaneiraNcmTec?.tipoIcms,
      necessidadeLi: aduaneiraNcmTec?.necessidadeLi,
      exTarifariosAmount: aduaneiraNcmTec?.exTarifarios?.length,
      exTarifariosII,
      exTarifariosIPI,
    };
  });

  const filteredRows = rows.filter((r) => {
    if (!filterDifferent) return true;

    return r.isIiDifferent || r.isIpiDifferent || r.isPisDifferent || r.isCofinsDifferent;
  });

  useEffect(() => {
    const ncmCodes = adicoes.reduce((acc, curr) => {
      if (acc.includes(curr?.ncm?.code)) return acc;

      return [...acc, curr?.ncm?.code];
    }, []);

    aduaneirasNcmTecAPI
      .fetchAllByNcmCodes([
        {
          name: 'ncmCodes',
          value: ncmCodes.join(';'),
        },
      ])
      .then((response) => {
        setAduaneirasNcmTec(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [adicoes]);

  useEffect(() => {
    if (!open) return;

    const metaAliquotas = { ...form.getState().values?.metaAliquotas };

    adicoes.forEach((a) => {
      metaAliquotas[a.id] = {
        ii: { checked: false },
        ipi: { checked: false },
        pis: { checked: false },
        cofins: { checked: false },
      };
    });

    form.change('metaAliquotas', metaAliquotas);
  }, [form, adicoes, open]);

  const aliquotaToNumber = (aliquota) => {
    const sanitizedValue = aliquota.replace(',', '.');
    const numericValue = Number.isNaN(Number(sanitizedValue)) ? sanitizedValue : Number(sanitizedValue);

    return numericValue;
  };

  const toggleAliquotaSelection = (id, accessor, value) => {
    const metaAliquotas = { ...(values?.metaAliquotas ?? {}) };

    const numericValue = aliquotaToNumber(value);

    if (metaAliquotas?.[id]?.[accessor]?.checked !== undefined) {
      metaAliquotas[id][accessor].checked = !metaAliquotas[id][accessor].checked;
      metaAliquotas[id][accessor].value = numericValue;
    } else {
      metaAliquotas[id] = {
        ...metaAliquotas[id],
        [accessor]: {
          checked: true,
          value: numericValue,
        },
      };
    }
    form.change('metaAliquotas', metaAliquotas);
  };

  const getRowCheckedValue = (id, accessor) => values?.metaAliquotas?.[id]?.[accessor]?.checked;

  const getRowAllCheckedValue = (id) => {
    const rowAliquotas = values?.metaAliquotas?.[id];

    const isAllChecked =
      rowAliquotas?.ii?.checked &&
      rowAliquotas?.ipi?.checked &&
      rowAliquotas?.pis?.checked &&
      rowAliquotas?.cofins?.checked;

    return isAllChecked;
  };

  const toggleRowAllCheckedByRow = (id, row) => (_e, newValue) => {
    const metaAliquotas = { ...(values?.metaAliquotas ?? {}) };

    metaAliquotas[id] = {
      ii: { checked: newValue, valor: aliquotaToNumber(row.iiTec) },
      ipi: { checked: newValue, valor: aliquotaToNumber(row.ipiTec) },
      pis: { checked: newValue, valor: aliquotaToNumber(row.pisTec) },
      cofins: { checked: newValue, valor: aliquotaToNumber(row.cofinsTec) },
    };

    form.change('metaAliquotas', metaAliquotas);
  };

  const getColAllCheckedValue = (accessor) => {
    const metaAliquotas = values?.metaAliquotas ?? {};

    let isAllChecked = true;

    const pageStartsAtIndex = page * PAGE_SIZE;
    const pageEndsAtIndex = pageStartsAtIndex + PAGE_SIZE;

    const metaAliquotasKeys = Object.keys(metaAliquotas);
    const metaAliquotasCurrentPageKeys = metaAliquotasKeys.slice(pageStartsAtIndex, pageEndsAtIndex);

    metaAliquotasCurrentPageKeys.forEach((key) => {
      if (!metaAliquotas[key]?.[accessor]?.checked) {
        isAllChecked = false;
      }
    });

    return isAllChecked;
  };

  const toggleColAllCheckedByAccessor = (accessor, valueAccessor) => (_e, newValue) => {
    const metaAliquotas = { ...values?.metaAliquotas };

    const pageStartsAtIndex = page * PAGE_SIZE;
    const pageEndsAtIndex = pageStartsAtIndex + PAGE_SIZE;

    const pageRows = rows.slice(pageStartsAtIndex, pageEndsAtIndex);

    pageRows.forEach((row) => {
      metaAliquotas[row.id][accessor] = { checked: newValue, value: aliquotaToNumber(row[valueAccessor]) };
    });

    form.change('metaAliquotas', metaAliquotas);
  };

  const tarifasAduaneirasColumns = [
    {
      field: 'tarifas-aduaneiras-checkAllRow',
      headerName: 'Marcar todos',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ id, row }) => (
        <Checkbox
          key={JSON.stringify(values?.metaAliquotas)}
          checked={getRowAllCheckedValue(id)}
          onChange={toggleRowAllCheckedByRow(id, row)}
        />
      ),
    },
    {
      field: 'tarifas-aduaneiras-nroAdicao',
      headerName: t('com.muralis.qcx.adicao.label'),
      headerAlign: 'center',
      align: 'center',
      width: 70,
      valueGetter: ({ row }) => row.item,
      renderHeader: ColumnHeaderRenderer,
    },
    {
      field: 'tarifas-aduaneiras-ncm',
      headerName: t('com.muralis.qcx.NCM.label'),
      headerAlign: 'center',
      align: 'center',
      width: 90,
      valueGetter: ({ row }) => row.ncm?.code || '-',
      renderHeader: ColumnHeaderRenderer,
    },
    {
      field: 'tarifas-aduaneiras-ii-original',
      headerName: `%${t('com.muralis.qcx.ii')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => (
        <CheckboxCellRenderer value={row.iiOriginal} highlight={row.isIiDifferent} style={{ paddingLeft: 12 }} />
      ),
    },
    {
      field: 'tarifas-aduaneiras-ii-tec',
      headerName: `%${t('com.muralis.qcx.iiTec')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ({ ...props }) => (
        <ColumnHeaderRenderer
          checked={getColAllCheckedValue('ii')}
          onChange={toggleColAllCheckedByAccessor('ii', 'iiTec')}
          {...props}
        />
      ),
      renderCell: ({ id, row }) => (
        <CheckboxCellRenderer
          key={JSON.stringify(values?.metaAliquotas)}
          value={row.iiTec}
          isSelected={getRowCheckedValue(id, 'ii')}
          toggleSelection={() => toggleAliquotaSelection(id, 'ii', row.iiTec)}
          highlight={row.isIiDifferent}
          style={{ paddingRight: 12 }}
        />
      ),
    },
    {
      field: 'tarifas-aduaneiras-ipi-original',
      headerName: `%${t('com.muralis.qcx.ipi')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => (
        <CheckboxCellRenderer value={row.ipiOriginal} highlight={row.isIpiDifferent} style={{ paddingLeft: 12 }} />
      ),
    },
    {
      field: 'tarifas-aduaneiras-ipi-tec',
      headerName: `%${t('com.muralis.qcx.ipiTec')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ({ ...props }) => (
        <ColumnHeaderRenderer
          checked={getColAllCheckedValue('ipi')}
          onChange={toggleColAllCheckedByAccessor('ipi', 'ipiTec')}
          {...props}
        />
      ),
      renderCell: ({ id, row }) => (
        <CheckboxCellRenderer
          value={row.ipiTec}
          key={JSON.stringify(values?.metaAliquotas)}
          isSelected={getRowCheckedValue(id, 'ipi')}
          toggleSelection={() => toggleAliquotaSelection(id, 'ipi', row.ipiTec)}
          highlight={row.isIpiDifferent}
          style={{ paddingRight: 12 }}
        />
      ),
    },
    {
      field: 'tarifas-aduaneiras-pis-original',
      headerName: `%${t('com.muralis.qcx.PIS')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => (
        <CheckboxCellRenderer value={row.pisOriginal} highlight={row.isPisDifferent} style={{ paddingLeft: 12 }} />
      ),
    },
    {
      field: 'tarifas-aduaneiras-pis-tec',
      headerName: `%${t('com.muralis.qcx.PIS_TEC')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ({ ...props }) => (
        <ColumnHeaderRenderer
          checked={getColAllCheckedValue('pis')}
          onChange={toggleColAllCheckedByAccessor('pis', 'pisTec')}
          {...props}
        />
      ),
      renderCell: ({ id, row }) => (
        <CheckboxCellRenderer
          value={row.pisTec}
          key={JSON.stringify(values?.metaAliquotas)}
          isSelected={getRowCheckedValue(id, 'pis')}
          toggleSelection={() => toggleAliquotaSelection(id, 'pis', row.pisTec)}
          highlight={row.isPisDifferent}
          style={{ paddingRight: 12 }}
        />
      ),
    },
    {
      field: 'tarifas-aduaneiras-cofins-original',
      headerName: `%${t('com.muralis.qcx.imposto.COFINS')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => (
        <CheckboxCellRenderer
          value={row.cofinsOriginal}
          highlight={row.isCofinsDifferent}
          style={{ paddingLeft: 12 }}
        />
      ),
    },
    {
      field: 'tarifas-aduaneiras-cofins-tec',
      headerName: `%${t('com.muralis.qcx.imposto.COFINS_TEC')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ({ ...props }) => (
        <ColumnHeaderRenderer
          checked={getColAllCheckedValue('cofins')}
          onChange={toggleColAllCheckedByAccessor('cofins', 'cofinsTec')}
          {...props}
        />
      ),
      renderCell: ({ id, row }) => (
        <CheckboxCellRenderer
          value={row.cofinsTec}
          key={JSON.stringify(values?.metaAliquotas)}
          isSelected={getRowCheckedValue(id, 'cofins')}
          toggleSelection={() => toggleAliquotaSelection(id, 'cofins', row.cofinsTec)}
          highlight={row.isCofinsDifferent}
          style={{ paddingRight: 12 }}
        />
      ),
    },
    {
      field: 'aliquota-siscomex-icms',
      headerName: t('com.muralis.qcx.ICMS.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: ({ row }) => row.tipoIcms,
      renderHeader: ColumnHeaderRenderer,
    },
    {
      field: 'aliquota-siscomex-ex-ii',
      headerName: 'Ex-II',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => {
        const checkedExTarifario = values?.metaAliquotas?.[`adicao_${row?.id}`]?.exTarifario?.ii;

        if (!row?.exTarifariosII?.length || row.exTarifariosII.length === 0) return null;

        return (
          <IconButton
            onClick={() => {
              setSelectedAdicaoId(row.id);
              setIsExTarifarioOpen(true);
              setExTarifariosSelected(row.exTarifariosII);
              setExTarifarioTipo('ii');
            }}
          >
            <Badge
              badgeContent={checkedExTarifario ? 1 : row.exTarifariosII.length}
              color={checkedExTarifario ? 'secondary' : 'primary'}
            />
          </IconButton>
        );
      },
    },
    {
      field: 'aliquota-siscomex-ex-tipi',
      headerName: 'Ex-TIPI',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: ColumnHeaderRenderer,
      renderCell: ({ row }) => {
        const checkedExTarifario = values?.metaAliquotas?.[`adicao_${row?.id}`]?.exTarifario?.ipi;

        if (!row?.exTarifariosIPI?.length || row.exTarifariosIPI.length === 0) return null;

        return (
          <IconButton
            onClick={() => {
              setSelectedAdicaoId(row.id);
              setExTarifariosSelected(row.exTarifariosIPI);
              setExTarifarioTipo('ipi');
              setIsExTarifarioOpen(true);
            }}
          >
            <Badge
              badgeContent={checkedExTarifario ? 1 : row.exTarifariosIPI.length}
              color={checkedExTarifario ? 'secondary' : 'primary'}
            />
          </IconButton>
        );
      },
    },
  ];

  const handleSaveButton = () => {
    handleSubmit(values);
    onClose();
  };

  return (
    <Dialog className={classes.root} open={open} onClose={onClose}>
      <DialogTitle>Consultar Tarifas Aduaneiras</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '75vh', overflow: 'auto' }}>
        {loading ? (
          <Box padding={8}>
            <LoadingIndicator />
          </Box>
        ) : (
          <Box width="100%">
            <>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <div>
                      <QCXFormTitle title="Alíquotas Tarifa Aduaneira" boxProps={{ mb: 3 }} />
                      <Box my={3}>
                        <FormControlLabel
                          label="Mostrar somente alíquotas com diferença"
                          name="tecwin.filterDifferent"
                          control={
                            <Checkbox
                              checked={filterDifferent}
                              onChange={(e) => setFilterDifferent(e.target.checked)}
                            />
                          }
                        />
                      </Box>
                      <QCXDataGrid
                        columns={tarifasAduaneirasColumns}
                        rows={filteredRows}
                        pageSize={PAGE_SIZE}
                        onPageChange={({ page: newPage }) => setPage(newPage)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <ExTarifarioPopup
                tipo={exTarifarioTipo}
                selectedAdicaoId={selectedAdicaoId}
                exTarifarios={exTarifariosSelected}
                open={isExTarifarioOpen}
                onClose={() => setIsExTarifarioOpen(false)}
              />
            </>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={3} display="flex" gridGap={16}>
          <QCXButton variant="outlined" color="primary" onClick={onClose}>
            Fechar
          </QCXButton>

          <QCXButton variant="contained" color="secondary" onClick={handleSaveButton}>
            Salvar
          </QCXButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const CheckboxCellRenderer = ({ value, isSelected, toggleSelection, style, highlight = false }) => {
  const isNumeric = !/[a-zA-Z]/.test(value ?? '');

  return (
    <div style={{ width: '100%', ...style }}>
      <div style={{ backgroundColor: highlight ? '#ed7a76' : undefined }}>
        {isNumeric && typeof toggleSelection === 'function' ? (
          <Checkbox checked={isSelected} onChange={toggleSelection} color="primary" />
        ) : null}
        <span>{value}</span>
      </div>
    </div>
  );
};

const ColumnHeaderRenderer = ({ checked, onChange, colDef }) => (
  <>
    {checked !== undefined ? <Checkbox checked={checked} onChange={onChange} /> : null}
    <span>{colDef.headerName}</span>
  </>
);

export default AuxiliarTributosPopup;
