import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Button, Grid } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUnidadeSelecionada } from "../../../../../../features/usuario-logado/usuarioLogadoSlice";
import ErrorBadge from "../../../../../common/components/forms/errorBadge";
import FormHeaderMedium from "../../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import LoadingIndicator from "../../../../../common/components/loadingIndicator";
import { useUnidadeNegocioGuard } from "../../../../../common/hooks/useUnidadeNegocioGuard";
import PageLayout from "../../../../../common/layouts/pageLayout";
import {  ClientIdAndDescriptionDTO } from "../../../../../common/types/clientData";
import { FollowUp, FollowUpDto } from "../../../../../common/types/followUp";
import {
  useDespesasItens,
  useDespesasCapa,
  useListAllCorrespondencias,
} from "../../totvs.hooks";
import {
  generateDespesasTXT,
  verifyDespesasCorrelations,
} from "./totvsEscritaDespesasPage.helpers";
import { fetchClientesWithFilterDto } from "../../../../../../features/cliente/clienteAPI";
import { fetchByFilterDto } from "../../../../../../features/follow-up/followUpAPI";
import { setErrorFeedback } from "../../../../../../features/feedback/feedbackSlice";
import { formatCnpj } from "../../../../../../utils/hooks/form/field/formatters";

const TotvsEscritaDespesasPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const keycloak = useKeycloak();
  const [error, setError] = useState<string | string[] | undefined>();
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const selectedCliente = useMemo(() => {
    const clienteFound = clientes?.find(c => c.id === cliente)

    if(!clienteFound){
      return null;
    }

    return {id: clienteFound.id, value: clienteFound.description};
  }, [cliente])
  
  
  const [followUpsData, setFollowUpsData] = useState<FollowUpDto[] | undefined>(undefined);
  

  const [
    correlacoesImportadores,
    isCorrelacoesImportadoresLoading,
    isCorrelacoesImportadoresError,
  ] = useListAllCorrespondencias();

  const {despesasCapa, isLoading: isLoadingCapas} = useDespesasCapa(processo);
  
  const {despesasItens, isLoading: isLoadingItens} = useDespesasItens(processo);

  const finalIsLoading = useMemo(() => isLoading || isLoadingCapas || isLoadingItens, [ isLoading, isLoadingCapas,isLoadingItens]);

  useEffect(() => {
    if (!correlacoesImportadores || !cliente) {
      setError(undefined);
      return;
    }

    const clienteCorrespondencia = correlacoesImportadores.find(
      (correlacao) => correlacao.quickcomexId === cliente?.toString()
    );

    if (!clienteCorrespondencia) {
      setError(
        "Cliente não possui correspondência no TOTVS. Criar a correspondência antes de prosseguir."
      );
    } else {
      setError(undefined);
    }

    return undefined;
  }, [cliente, correlacoesImportadores]);

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != "object") {
      return [];
    }

    const processosFilteredByClient = followUpsData.filter(
      (followUp: FollowUpDto) => followUp.importador.id === cliente
    );

    const processos: string[] = processosFilteredByClient.map(
      (followUp: FollowUpDto) => followUp.numero
    );
  

    // Remove duplicates
    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData, cliente]);

  const selectedProcesso = useMemo(() => {
    if (!processo) {
      return null;
    }

    const index = processos.findIndex((p) => p === processo);

    return {id: index, value: processo};
  }, [processo]);

  const handleReportGeneration = async () => {
    if (despesasCapa && despesasCapa.length > 0 && despesasItens) {
      const correlation = correlacoesImportadores?.find(
        (correlacao) => correlacao.quickcomexId === cliente?.toString()
      );
      const errors = verifyDespesasCorrelations(
        despesasCapa,
        despesasItens,
        correlation
      );
      if (errors.length > 0) {
        setError(errors);
      } else {
        const despesasCapaTXT = generateDespesasTXT(
          despesasCapa,
          despesasItens
        );
      }
      setIsLoading(false);
    }else{
      setIsLoading(false);
      setError('Erro ao obter despesas');
    }
  };

  const handleSubmit = () => {
    setError(undefined);
    setIsLoading(true);
    handleReportGeneration();
  };

  const clearData = () => {
    setCliente(undefined);
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    if (clientes) {
      const selectedClient = clientes.find(
        (cliente) => cliente.id === value?.id
      );

      if (selectedClient) {
        setCliente(selectedClient.id);
        setProcesso(undefined);
      }
    }
  };
  

  const getClientsAndFollowUps = async () => {
    try {
      const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(data);

    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter clientes:'
      }));
    }

    try {
      const { data } = await fetchByFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setFollowUpsData(data);

    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter FollowUps:'
      }));
    }

  };

  useEffect(() => {
    getClientsAndFollowUps();
  }, []);

  return (
    <PageLayout
      title={"TOTVS - Escrita de DI / Despesas"}
      icon={<InsertDriveFileIcon color={"secondary"} />}
    >
      {finalIsLoading ? (
        <LoadingIndicator
          message={finalIsLoading ? "Gerando Txt" : "Carregando Dados..."}
        />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>
            Selecione as informações para gerar o arquivo
          </FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={(clientes || []).map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + " - " + cliente.id + " - " + formatCnpj(cliente?.pessoa?.cnpj),
              }))}
              controlledValue={selectedCliente}
              onChangeAction={handleClienteChange}
              label={"Cliente"}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={"Processo"}
              controlledValue={selectedProcesso}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
                setError(undefined);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={"right"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !processo || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default TotvsEscritaDespesasPage;
