import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';

import QCXAtributoBooleano from './components-atributos/QCXAtributoBooleano';
import QCXAtributoListaEstatica from './components-atributos/QCXAtributoListaEstatica';
import QCXAtributoNumeroInteiro from './components-atributos/QCXAtributoNumeroInteiro';
import QCXAtributoNumeroReal from './components-atributos/QCXAtributoNumeroReal';
import QCXAtributoTextField from './components-atributos/QCXAtributoTextField';
import { evaluateExpression } from './components-atributos/metadados-utils';

/*
        Esse componente pode ter 1 ou mais filhos, dependendo do tipo de atributo, caso o atributo tenha subatributos
    esse componente em questão é responsavel por renderizar e gerenciar os subatributos e o mesmo é validos para os
    atributos condicionados. Ou seja, ao passar um atributo como props para esse compoente ele vai gerenciar o atributo
    em si e os seus filhos (seja subatributos ou condicionado).
*/

export default function QCXRenderConditionalComponent({atributo, isConsult, disabled, ...restProps}) {

    const [listaAtributosCondicionantesAtivo, setListaAtributosCondicionantesAtivo] = useState([]);
    const [dictAtributosCondicionados, setDictAtributosCondicionados] = useState({});

    const atributoPossuiObjetivoProduto = useMemo(() => {
      const codigoObjetivoProduto = '7';
      const objetivoProduto = atributo?.detalhesAtributos?.objetivos?.find((o) => o.codigo === codigoObjetivoProduto);
  
      return !!objetivoProduto;
    }, [atributo]);

    const extrairAtributosCondicionados = (condicionados, condicionador) => {
        if(!condicionados || (condicionados && !_.isArray(condicionados))) return [];
        if (condicionados.length === 0) return [];
    
        let atributosExtraidos = []
    
        condicionados.forEach(condicionado => {
          const atributoCondionado = _.get(condicionado, "atributo");
          
          const atributoAtual = {
            id_condicionador: condicionador, // caso esse campo dependa de outro atributo
            ...condicionado,
            isCondicionador: false, // se existem campos que dependem do valor desse atributo
          }

          if (atributoCondionado && atributoCondionado.condicionados && _.isArray(atributoCondionado.condicionados) && atributoCondionado.condicionados.length) {
            atributoAtual.isCondicionador = true;
          }

          atributosExtraidos.push(atributoAtual)
    
          if (atributoCondionado && atributoCondionado.condicionados && _.isArray(atributoCondionado.condicionados)) {
            // recursao
            const atributosAninhados = extrairAtributosCondicionados(atributoCondionado.condicionados, atributoCondionado.codigo);
            atributosExtraidos = atributosExtraidos.concat(atributosAninhados)
          }
    
        })
    
        return atributosExtraidos;
    
    }

    useEffect(() => {
        const condicionantes = _.get(atributo, "detalhesAtributos.condicionados")
        const listaAtributosCondicionados = extrairAtributosCondicionados(condicionantes, atributo.detalhesAtributos.codigo);
        const dictAtributos = {}

        listaAtributosCondicionados.forEach(atributoAtual => {
            const condicionador = atributoAtual.id_condicionador;
            if (!dictAtributos[condicionador]) {
                dictAtributos[condicionador] = []
            }
            dictAtributos[condicionador].push({...atributoAtual, isAtivo: false})
        })

        setDictAtributosCondicionados({...dictAtributos});

    },[atributo])


    /*
        * value  -> valor que foi alterado
        * codigo -> codigo do campo que teve o valor alterado
        
        * desc: a funcao avalia se esse atributo alterado eh condicionador de outro campo,
        caso seja verifica a condicao para exibir ou nao os campos dependentes
    */
   
    const avaliaCondicionantesEvent = (value, codigo) => {
        // const condicionantes = _.get(atributo, "detalhesAtributos.condicionados")
        
        // avalia campos condicionados
        const atributosCondicionados = dictAtributosCondicionados;
        if (atributosCondicionados && atributosCondicionados[codigo] && _.isArray(atributosCondicionados[codigo])) {
            
            let arrCondicionados = atributosCondicionados[codigo];

            arrCondicionados = arrCondicionados.map(condicionado => {
                condicionado.isAtivo = evaluateExpression(value, condicionado.condicao);
                return condicionado;
            })

            atributosCondicionados[codigo] = [...arrCondicionados];
            setDictAtributosCondicionados({...atributosCondicionados});
        }

/*

        if(condicionantes && Array.isArray(condicionantes) && condicionantes.length) {
            setListaAtributosCondicionantesAtivo([])
            const atributosAtivos = condicionantes.filter(condicionado => evaluateExpression(value, condicionado.condicao))
            if(atributosAtivos.length) {
                setListaAtributosCondicionantesAtivo([...atributosAtivos])
            }
        }
*/
    }
/*
    const renderComponentesCondicionantesOld = () => {
        const flag = 1
        return listaAtributosCondicionantesAtivo.map(atributoAtual => (
            mountCompoundItem({...atributoAtual.atributo, detalhesAtributos: atributoAtual.atributo}, true)
        ))
    }
*/
    const renderComponentesCondicionantes = () => {
        const codigosArr = Object.keys(dictAtributosCondicionados);

        
        const atributosAtivos = []
        codigosArr.forEach(codigoAtual => {
            const atributosCondicionadosAtual = dictAtributosCondicionados[codigoAtual];
            atributosCondicionadosAtual.forEach(atributoAtual => {
                const codAtributoAtual = atributoAtual.atributo.codigo
                let condicionador = false
                
                if(dictAtributosCondicionados[codAtributoAtual] && _.isArray(dictAtributosCondicionados[codAtributoAtual]) && dictAtributosCondicionados[codAtributoAtual].length) {
                    condicionador = true;
                }
                if (atributoAtual.isAtivo) {
                    const componentAtributo = mountCompoundItem({...atributoAtual.atributo, condicionador, detalhesAtributos: atributoAtual.atributo}, true);
                    atributosAtivos.push(componentAtributo)
                }
            })
        })

        return atributosAtivos
    }

    const getComponentCompound = () => {
        const subatributos = _.get(atributo, "detalhesAtributos.listaSubatributos");
        const codPai = _.get(atributo, "detalhesAtributos.codigo");
        if(_.isArray(subatributos)) {
            return subatributos.map(subatributo => {
                const flag = 1;
                return mountCompoundItem({...subatributo, detalhesAtributos: subatributo}, false, true, codPai)
            })
        }
        return <></>
    }
    
    const mountCompoundItem = (atributoObj, isCondicionado = false, isComposto = false, codAtributoPai = "") => {
        if(!_.isObject(atributoObj)) {
            atributoObj = atributo
        }

        if(!atributoObj || (atributoObj && !atributoObj.detalhesAtributos)) return null;

        const dictComponents = {
            'TEXTO' : () => (
                <Grid item xs={12} sm={6} md={4}>
                    <QCXAtributoTextField
                        atributo={atributoObj}
                        disabled={disabled}
                        avaliaCondicionantesEvent={avaliaCondicionantesEvent}
                        isCondicionado={isCondicionado}
                        isComposto={isComposto}
                        codAtributoPai={codAtributoPai}
                    />
                </Grid>
            ),
            'LISTA_ESTATICA': () => (
                <Grid item xs={12} sm={6} md={4}>
                    <QCXAtributoListaEstatica 
                        atributo={atributoObj}
                        disabled={disabled}
                        avaliaCondicionantesEvent={avaliaCondicionantesEvent}
                        isCondicionado={isCondicionado}
                        isComposto={isComposto}
                        codAtributoPai={codAtributoPai}
                    />
                </Grid>
            ),
            'BOOLEANO': () => (
                <Grid item xs={12} sm={6} md={4}>
                    <QCXAtributoBooleano
                        atributo={atributoObj}
                        disabled={disabled}
                        avaliaCondicionantesEvent={avaliaCondicionantesEvent}
                        isCondicionado={isCondicionado}
                        isComposto={isComposto}
                        codAtributoPai={codAtributoPai}
                    />
                </Grid>
            ),
            'NUMERO_REAL': () => (
                <Grid item xs={12} sm={6} md={4}>
                    <QCXAtributoNumeroReal
                        atributo={atributoObj}
                        disabled={disabled}
                        avaliaCondicionantesEvent={avaliaCondicionantesEvent}
                        isCondicionado={isCondicionado}
                        isComposto={isComposto}
                        codAtributoPai={codAtributoPai}
                    />
                </Grid>
            ),
            'NUMERO_INTEIRO': () => (
                <Grid item xs={12} sm={6} md={4}>
                    <QCXAtributoNumeroInteiro 
                        atributo={atributoObj}
                        disabled={disabled}
                        avaliaCondicionantesEvent={avaliaCondicionantesEvent}
                        isCondicionado={isCondicionado}
                        isComposto={isComposto}
                        codAtributoPai={codAtributoPai}
                    />
                </Grid>
            ),
            'COMPOSTO': () => getComponentCompound(),
            
        }

        if(!atributo || (atributo && !atributo.detalhesAtributos)) {
            console.error("ERROR: Atributo incorreto !!")
        }

        const componentFunctionRender = dictComponents[atributoObj.detalhesAtributos.formaPreenchimento]

        if(!componentFunctionRender) {
            console.error("ERROR: caso nao tratado !!")
            return (
                <>ERROR: caso nao tratado !!</>
            )
        }
        

        return componentFunctionRender()
    }

  if (!atributoPossuiObjetivoProduto) return null;
    
  return (
    <>
        {mountCompoundItem(atributo)}
        {renderComponentesCondicionantes()}
    </>
  );
}
