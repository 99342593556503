import { Link } from '@material-ui/core';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Button, Grid } from '@mui/material';
import * as _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import QCXInfoAlert from '../../../../../shared-components/alert/QCXInfoAlert';
import { MediaType } from '../../../../../utils/api/api-utils';
import { required } from '../../../../../utils/validators/field/validator';
import FileDragAndDrop from '../../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../../common/components/forms/formHeaderMedium';
import PageLayout from '../../../../common/layouts/pageLayout';
import { useKeycloak } from '@react-keycloak/web';
import { selectUnidadeSelecionada } from '../../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../../App';
import SelectStyled from '../../../../common/components/input/selectStyled';
import { SimpleServicoDTO } from '../../../../common/types/servico';
import { ClientIdAndDescriptionDTO } from '../../../../common/types/clientData';
import { fetchClientesWithFilterDto } from '../../../../../features/cliente/clienteAPI';
import { fetchByFilterDto } from '../../../../../features/servico/servicoAPI';
import thermoFisherPageHelpers from '../ThermoFisherPage.helpers';
import ModalRelatorio from '../../../../../shared-components/snackbar/ModalRelatorio';
import ProgressBarModal, { ProgressBarStep } from '../../planilhas/components/progressModal';

export interface BasicFormData {
  arquivo: File;
  cliente: {
    id: number;
  };
  servico: {
    id: number;
  };
}

const ThermoFisherLeituraPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFollowUp, setCurrentFollowUp] = useState<string | null | undefined>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const [currentMessage, setCurrentMessage] = useState(null);

  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const [error, setError] = useState<string | string[] | undefined>();
  const [servico, setServico] = useState<number | undefined>(undefined);
  const [servicosData, setServicosData] = useState<SimpleServicoDTO[] | undefined>(undefined);
  const [titleModal, setTitleModal] = useState('Alerta');

  const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
    step: 0,
    description: 'Criando Faturas',
  });

  const baseUrl =
    process.env.REACT_APP_QCX_API_BASE_URL! +
    '/' +
    process.env.REACT_APP_QCX_API_FATURA_ENDPOINT! +
    '/thermo-fisher/leitura';

  useEffect(() => {
    if (!cliente) {
      setError(undefined);
      return;
    }

    return undefined;
  }, [cliente]);

  useEffect(() => {
    if (currentStep.step > 0 && currentStep.step < 4) {
      const timer = setTimeout(() => {
        setCurrentStep((prevStep) => {
          switch (prevStep.step) {
            case 1:
              setIsModalOpen(true);
              return { step: 2, description: 'Continuando processo' };
            case 2:
              setIsModalOpen(true);
              return { step: 3, description: 'Verificando partnumbers' };
            case 3:
              setIsModalOpen(true);
              return { step: 4, description: 'Verificando partnumbers' };
            case 4:
              setIsModalOpen(true);
              return { step: 5, description: 'Verificando partnumbers' };
            case 5:
              setIsModalOpen(true);
              return { step: 6, description: 'Finalizando processo' };
            case 6:
              setIsModalOpen(true);
              return { step: 7, description: 'Finalizando processo' };
            default:
              return prevStep;
          }
        });
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [currentStep.step]);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (cliente !== undefined) {
      fetchServicos(cliente);
    }
  }, [cliente, dispatch]);

  const fetchServicos = async (clienteId: number) => {
    try {
      const { data: servicosData } = await fetchByFilterDto([
        { name: 'clienteId', value: clienteId },
        { name: 'produto', value: 'FATURA' },
        { name: 'filterDadosMinimos', value: 'TRUE' },
      ]);
      setServicosData(servicosData);
    } catch (error) {
      dispatch(setErrorFeedback({ message: 'Erro ao obter serviços' }));
    }
  };

  const handleServicoChange = (event: SyntheticEvent<Element, Event>, value: newValueIdValue | null, form: any) => {
    if (value) {
      setServico(Number(value.id));
      form.change('servico', { id: value.id });
    }
  };

  interface newValueIdValue {
    id?: string | number | undefined;
    value?: string;
  }

  const handleClienteChange = (event: SyntheticEvent<Element, Event>, value: newValueIdValue | null, form: any) => {
    if (clientes) {
      const selectedClient = clientes.find((cliente) => cliente.id === value?.id);

      if (selectedClient) {
        setCliente(selectedClient.id);
        form.change('cliente', { id: selectedClient.id });
      }
    }
  };

  const getClients = async () => {
    try {
      const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(data);
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: 'Erro ao obter clientes:',
        })
      );
    }
  };

  const resetProgressBar = useCallback(() => {
    setCurrentStep({ step: 0, description: 'Criando Faturas' });
  }, []);

  const [decisionPromiseResolver, setDecisionPromiseResolver] = useState<(value: boolean) => void>(() => {});

  const handleConfirm = () => {
    decisionPromiseResolver(true);
  };

  const handleCancel = () => {
    decisionPromiseResolver(false);
  };

  const clearData = () => {
    setIsLoading(false);
    setIsModalOpen(false);
    resetProgressBar();
  };

  const handleFaturasSubmit = async (values: BasicFormData) => {
    setIsModalOpen(true);
    setCurrentStep({ step: 1, description: 'Criando Faturas' });

    if (selectedUnidade && token) {
      try {
        const respSpreadsheet = await thermoFisherPageHelpers.uploadFile(values, selectedUnidade, token, baseUrl);

        if (respSpreadsheet.status !== 200) {
          const errorMessage = respSpreadsheet.data
            ? respSpreadsheet.data
            : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha');
          setCurrentMessage(errorMessage);
          setTitleModal('error');
          setConfirmModalOpen(true);
        } else {
          const sucessMessage = respSpreadsheet.data ? respSpreadsheet.data : t('com.muralis.qcx.estado.concluido');
          setCurrentMessage(sucessMessage);
          setTitleModal('success');
          setConfirmModalOpen(true);
        }
      } catch (error: any) {
        console.log(error);
        setCurrentMessage(error.message);
        setTitleModal('error');
        setConfirmModalOpen(true);
      } finally {
        clearData();
      }
    }
  };

  return (
    <PageLayout title={'Thermo / Fisher'} icon={<HistoryEduIcon color={'secondary'} />}>
      <>
        <ProgressBarModal
          title="Importando planilha"
          open={isModalOpen}
          totalSteps={7}
          currentStep={currentStep}
          onClose={() => setIsModalOpen(false)}
        />

        <ModalRelatorio
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          content={currentMessage}
          severity={titleModal}
        />
        <Form onSubmit={(values: BasicFormData) => handleFaturasSubmit(values)}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <FormHeaderMedium>Informações básicas</FormHeaderMedium>
                  <Grid item xs={6}>
                    <SelectStyled
                      options={(clientes || []).map((cliente, index) => ({
                        id: cliente.id,
                        value: cliente.description + ' - ' + cliente.id,
                      }))}
                      onChangeAction={(event, newValue) => handleClienteChange(event, newValue, form)}
                      label={'Cliente'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectStyled
                      options={(servicosData || []).map((servico) => ({
                        id: servico.id,
                        value: servico.nome,
                      }))}
                      label={'Serviço'}
                      onChangeAction={(event, newValue) => handleServicoChange(event, newValue, form)}
                    />
                  </Grid>
                  <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                  <Field name="arquivo" validate={required}>
                    {({ input }) => <FileDragAndDrop input={input} accept={MediaType.EXCEL} />}
                  </Field>
                  <Grid item xs={12} textAlign="right">
                    <Button
                      disabled={!cliente}
                      color="secondary"
                      variant="contained"
                      onClick={() => handleFaturasSubmit(values)}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </>
    </PageLayout>
  );
};

export default ThermoFisherLeituraPage;
