import { useTranslation } from "react-i18next";
import PageLayout from "../../../common/layouts/pageLayout";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from "react-router";
import { Button, FormControl, Grid, Typography } from '@mui/material';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { useEffect, useState, SyntheticEvent } from "react";
import {
    selectUnidadesDeNegocioAssociadas,
    selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../App";
import { setErrorFeedback, setSuccessFeedback } from "../../../../features/feedback/feedbackSlice";
import { generateDespesasPagasReport } from "./despesasPagasPorFornecedorPage.helpers";
import MultiSelectStyled, { SimpleMultiSelectOption } from "../../../common/components/input/multiSelectStyled";
import { Form, Field } from 'react-final-form';

const DespesasPagasPorFornecedorPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { token } = keycloak;
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    const tenantList = useSelector(selectUnidadesDeNegocioAssociadas);
    const selectedUnit = useSelector(selectUnidadeSelecionada); 
    const [selectedTenants, setSelectedTenants] = useState<SimpleMultiSelectOption[]>([]);

    useEffect(() => {
        // Fetch any other data necessary for this report
    }, []);

    const handleTenantsChange = (
        event: SyntheticEvent<Element, Event>,
        value: SimpleMultiSelectOption[] | null
    ) => {
        setSelectedTenants(value || []);
    };

    const handleSubmit = async (values: any) => {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

        if (selectedTenants.length === 0) {
            dispatch(setErrorFeedback({ message: 'Selecione pelo menos uma Unidade de Negócio.' }));
            return;
        }

        const { startDate, endDate } = values;

        if (!startDate || !endDate) {
            dispatch(setErrorFeedback({ message: 'Selecione as datas de início e fim.' }));
            return;
        }
        
        if (token && selectedUnit) { 
            try {
                const tenantIds = selectedTenants.map(tenant => tenant.id.toString());

                const error = await generateDespesasPagasReport(token, selectedUnit, tenantIds, startDate, endDate); 
                if (error) {
                    dispatch(setErrorFeedback({ message: error }));
                } else {
                    dispatch(setSuccessFeedback({ message: 'Relatório gerado com sucesso!' }));
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao gerar o relatório.' }));
            }
        }
    };

    return (
        <PageLayout title={'Despesas Pagas Por Fornecedor'} icon={<InsertDriveFileIcon color={'secondary'} />}>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginBottom: '25px' }}>
                                <FormControl fullWidth error={selectedTenants.length === 0}>
                                    <MultiSelectStyled
                                        controlledValues={selectedTenants}
                                        options={tenantList.map((tenant: { id: any; pessoa: { nomeResumido: any; }; }) => ({
                                            id: tenant.id,
                                            value: tenant.pessoa.nomeResumido
                                        }))}
                                        onChangeAction={handleTenantsChange}
                                        label={"Unidade de Negócio"}
                                    />
                                    {selectedTenants.length === 0 && (
                                        <Typography variant="body2" color="error">
                                            Este campo é obrigatório
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item sm={6} style={{ marginBottom: '25px' }}>
                                <Field
                                    name="startDate"
                                    render={({ input }) => (
                                        <QCXFinalDatePickerField
                                            id="date-picker-start-date"
                                            name={input.name}
                                            label={"Data de Início"}
                                            required={true}
                                            format="DD/MM/YYYY"
                                            placeholder="DD/MM/YYYY"
                                            onChange={(date: any) => input.onChange(date)}
                                            validate={undefined}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6} style={{ marginBottom: '25px' }}>
                                <Field
                                    name="endDate"
                                    render={({ input }) => (
                                        <QCXFinalDatePickerField
                                            id="date-picker-end-date"
                                            name={input.name}
                                            label={"Data de Fim"}
                                            required={true}
                                            format="DD/MM/YYYY"
                                            placeholder="DD/MM/YYYY"
                                            onChange={(date: any) => input.onChange(date)}
                                            validate={undefined}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    fullWidth
                                    disabled={selectedTenants.length === 0}
                                >
                                    Gerar relatório
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </PageLayout>
    );
};

export default DespesasPagasPorFornecedorPage;
