import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Grid } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ErrorBadge from '../../../../../common/components/forms/errorBadge';
import FormHeaderMedium from '../../../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../../../common/components/loadingIndicator';
import { useUnidadeNegocioGuard } from '../../../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../../../common/layouts/pageLayout';
import { ClientIdAndDescriptionDTO } from '../../../../../common/types/clientData';
import { FollowUpDto } from '../../../../../common/types/followUp';
import { useListAllCorrespondencias, useNumerario } from '../../totvs.hooks';
import { CorrespondenciaImportadorTotvs } from '../../totvs.types';
import { generateNumerarioTXT, verifyNumerariosCorrelations } from './totvsEscritaNumerariosPage.helpers';
import { fetchClientesWithFilterDto } from '../../../../../../features/cliente/clienteAPI';
import { setErrorFeedback } from '../../../../../../features/feedback/feedbackSlice';
import { fetchByFilterDto } from '../../../../../../features/follow-up/followUpAPI';
import { formatCnpj } from '../../../../../../utils/hooks/form/field/formatters';

const TotvsEscritaNumerariosPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | string[] | undefined>();
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const [followUpsData, setFollowUpsData] = useState<FollowUpDto[] | undefined>(undefined);

  const [correlacoesImportadores, isCorrelacoesImportadoresLoading, isCorrelacoesImportadoresError] =
    useListAllCorrespondencias();

  const { data: numerarios, isloading: isLoadingNumerarioData } = useNumerario(processo);

  useEffect(() => {
    if (!correlacoesImportadores || !cliente) {
      setError(undefined);
      return;
    }

    const clienteCorrespondencia = correlacoesImportadores.find(
      (correlacao: CorrespondenciaImportadorTotvs) => correlacao.quickcomexId === cliente?.toString()
    );

    if (!clienteCorrespondencia) {
      setError('Cliente não possui correspondência no TOTVS. Criar a correspondência antes de prosseguir.');
    } else {
      setError(undefined);
    }

    return undefined;
  }, [cliente, correlacoesImportadores]);

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != 'object') {
      return [];
    }

    const processosFilteredByClient = followUpsData.filter(
      (followUp: FollowUpDto) => followUp.importador.id === cliente
    );

    const processos: string[] = processosFilteredByClient.map((followUp: FollowUpDto) => followUp.numero);

    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData, cliente]);

  const handleReportGeneration = async () => {
    if (numerarios && numerarios.length > 0) {
      const correlation = (correlacoesImportadores ?? []).find(
        (correlacao: CorrespondenciaImportadorTotvs) => correlacao.quickcomexId === cliente?.toString()
      );
      try {
        const errors = verifyNumerariosCorrelations(numerarios, correlation);
        if (errors.length > 0) {
          setError(errors);
        } else {
          generateNumerarioTXT(numerarios);
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    } else {
      setError('Não foi possível obter os numerários');
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setError(undefined);
    setIsLoading(true);
    handleReportGeneration();
  };

  const clearData = () => {
    setCliente(undefined);
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    if (clientes) {
      const selectedClient = clientes.find((cliente) => cliente.id === value?.id);

      if (selectedClient) {
        setCliente(selectedClient.id);
        setProcesso(undefined);
      }
    }
  };

  const getClientsAndFollowUps = async () => {
    try {
      const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(data);
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: 'Erro ao obter clientes:',
        })
      );
    }

    try {
      const { data } = await fetchByFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setFollowUpsData(data);
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: 'Erro ao obter FollowUps:',
        })
      );
    }
  };

  useEffect(() => {
    getClientsAndFollowUps();
  }, []);

  const finalLoading = useMemo(() => {
    return isLoading || isCorrelacoesImportadoresLoading || isLoadingNumerarioData;
  }, [isLoading, isCorrelacoesImportadoresLoading, isLoadingNumerarioData]);

  return (
    <PageLayout title={'TOTVS - Escrita de Numerários'} icon={<InsertDriveFileIcon color={'secondary'} />}>
      {finalLoading ? (
        <LoadingIndicator message={isLoading ? 'Gerando Txt' : 'Carregando Dados...'} />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>Selecione as informações para gerar o arquivo</FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={(clientes || []).map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + ' - ' + cliente.id + ' - ' + formatCnpj(cliente?.pessoa?.cnpj),
              }))}
              onChangeAction={handleClienteChange}
              label={'Cliente'}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={'Processo'}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={'right'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !processo || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default TotvsEscritaNumerariosPage;
