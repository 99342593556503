import React, { useEffect, useMemo, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';

import QCXFinalCheckboxField from '../../../shared-components/final-checkbox-field/QCXFinalCheckboxField'; // boolean
import { evaluateExpression } from './metadados-utils'


export default function QCXAtributoBooleano({
    atributo, 
    avaliaCondicionantesEvent, 
    disabled, 
    isConsult, 
    isCondicionado,     // condicionado significa que a exibicao desse atributo depende do valor de outro atributo
    isComposto = false,
    codAtributoPai,    
    ...restProps
}) {

    const [label, setLabel] = useState("")
    const [name,  setName] = useState("")
    const [obrigatorio, setObrigatorio] = useState(false);
    const [codigo, setCodigo] = useState("");
    const path = `atributosPreenchidos`
    const form = useForm();

    const verificaCondicionante = (value) => {
        const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
        if(condicionados && _.isArray(condicionados) && condicionados.length && _.isFunction(avaliaCondicionantesEvent)) {
            avaliaCondicionantesEvent(value, codigo)
        }
    }

    useEffect(() => {
    
        if(atributo && atributo.detalhesAtributos) {
            const detalhes = atributo.detalhesAtributos;
            const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
            let pathName = ""
            if(isComposto && codAtributoPai) {
                pathName = `${path}.atributosCompostos.${codAtributoPai}.valores.${detalhes.codigo}.valor`
            } else {
                pathName = `${path}.atributos.${detalhes.codigo}.valor`
            }

            setLabel(detalhes.nomeApresentacao)
            setCodigo(detalhes.codigo)
            setName(pathName)
            setObrigatorio(detalhes.obrigatorio)

            const stateBool = _.get(form.getState()?.values, pathName)

            if(stateBool === undefined || stateBool === null) {
                setTimeout(() => {
                    form.change(pathName, false)
                }, 250);
            } else if(_.isArray(condicionados) && condicionados.length && !isCondicionado){
                // eh um atributo condicionante
                verificaCondicionante(stateBool)
            }
        }

        return null;
    }, [atributo, form, codAtributoPai])

    if(!name) {
        return null
    }
    
    return (
        <>
            <QCXFinalCheckboxField
                id={codigo}
                key={codigo}
                name={name}
                label={label}
                disabled={disabled}
                {...restProps}
            />

            <OnChange name={name}>
                {(value, previous) => verificaCondicionante(value)}
            </OnChange>
        </>

    );
    

}
