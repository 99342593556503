import React from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import QCXEmpresaEstrangeiraForm from '../../../../components/empresa-estrangeira/QCXEmpresaEstrangeiraForm';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  addToList,
  resetModel,
  setModel,
  success,
  setResponse,
} from '../../../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { register } from '../../../../features/empresa-estrangeira/empresaEstrangeiraAPI';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';

interface EmpresaModalProps {
  open: boolean;
  onClose: () => void;
  authInfo?: any;
}

const EmpresaModal: React.FC<EmpresaModalProps> = ({ open, onClose, authInfo = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDispatchSetModel = (data: any) => {
    dispatch(setModel(data));
  };

  const create = async (data: any) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          dispatch(success());
          dispatch(
            setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.empresaEstrangeiraCadastrada', {
                empresa: created?.pessoaJuridica?.nome,
              }),
            })
          );
          onClose();
          dispatch(
            setSuccessFeedback({
              message: t('com.muralis.qcx.mensagem.empresaEstrangeiraCadastrada', {
                empresa: created?.pessoaJuridica?.nome,
              }),
            }))
        }
      } catch ({ response }: any) {
        console.error(response);
      }
    }, 500);

    executeDebounced();
  };

  const handleSubmit = async (data: any) => {
    await create(data);
  };

  const pageTitle = t('com.muralis.qcx.empresaEstrangeira.novaEmpresaEstrangeira');
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisEmpresaEstrangeira'),
        name: t('com.muralis.qcx.empresaEstrangeira.labelPlural'),
      },
    },
    {
      text: {
        name: pageTitle,
      },
    },
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{
        padding: '20px',
        margin: '5% auto',
        maxWidth: '80%',
        width: '100%',
        boxSizing: 'border-box',
      }}>
        <QCXRegistrationFormPageTemplate
          pageTitle={pageTitle}
          pageIcon={<AddCircleIcon />}
          breadcrumbs={breadcrumbs}
          isCreate={true}
          isUpdate={false}
          isConsult={false}
          isBackgroundCreate={false}
          isPreparingAction={false}
          handleChangeToPreparingAction={() => { }}
          handleChangeToCreate={() => { }}
          handleChangeToBackgroundCreate={() => { }}
          handleChangeToConsult={() => { }}
          handleChangeToUpdate={() => { }}
          handleCancelUpdate={() => { }}
          handleResetBackgroundMode={() => { }}
          authInfo={authInfo}
          actionBarProps={{}}
        >
          {(formProps: any) => (
            <QCXEmpresaEstrangeiraForm
              initialValues={{}}
              handleChangeModel={handleDispatchSetModel}
              handleSubmit={handleSubmit}
              authInfo={authInfo}
              requiredRoles={['exportador-fabricante']}
              showClientes={false}
              desativaImportador={true}
              {...formProps}
            />
          )}
        </QCXRegistrationFormPageTemplate>
      </div>
    </Modal>
  );
};

export default EmpresaModal;
