// src/pages/Relatorios/RelatoriosPage.tsx

import AssessmentIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../common/layouts/basicLayout';

const financeiroGridItems: CardsGridItem[] = [
  {
    title: 'Contas a Pagar por Data de Vencimento',
    icon: InsertDriveFileIcon,
    path: '/financeiro/relatorios/contas-pagar-por-data-vencimento',
  },
  {
    title: 'Despesas Pagas por Fornecedor Cliente e Data de Pagamento',
    icon: InsertDriveFileIcon,
    path:  '/financeiro/relatorios/despesas-pagas-por-fornecedor',
  },
  {
    title: 'Contas a Receber',
    icon: InsertDriveFileIcon,
    path: '/financeiro/relatorios/contas-receber',
  },
  {
    title: 'Contas Recebidas',
    icon: InsertDriveFileIcon,
    path: '/financeiro/relatorios/contas-recebidas',
  },
  {
    title: 'Mapa de Processos',
    icon: InsertDriveFileIcon,
    path: '/financeiro/relatorios/mapa-processos',
  },
  {
    title: 'Processos Desembaraçados',
    icon: InsertDriveFileIcon,
    path: '/financeiro/relatorios/processos-desembaracados',
  }
];

const FinanceiroRelatoriosPage: React.FC = () => {
    return (
        <BasicLayout title={'Relatórios Financeiros'} icon={<AssessmentIcon color={'secondary'} />}>
            <CardsGrid items={financeiroGridItems}></CardsGrid>
        </BasicLayout>
    );
};

export default FinanceiroRelatoriosPage;


